import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { RootState } from '../../store/store';
import { logout } from '../../store/slices/authSlice';
import { UserRole } from '../../types/user';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const NavBar: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch(logout());
    setIsDropdownOpen(false);
  };

  const navItems = user
    ? [
        { to: "/dashboard", label: "Dashboard" },
        { to: "/settings", label: "My Settings" },
      ]
    : [
        { to: "/features", label: "Features" },
        { to: "/about", label: "About" },
        { to: "/contact", label: "Contact" },
      ];

  const navLinkStyles = (isActive: boolean) => ({
    color: 'white',
    backgroundColor: isActive ? 'rgba(79, 70, 229, 0.9)' : 'transparent',
    padding: '0.5rem 1rem',
    borderRadius: '0.375rem',
    transition: 'all 0.3s ease',
    ':hover': {
      backgroundColor: 'rgba(124, 58, 237, 0.5)',
    },
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownItemClick = (path: string) => {
    navigate(path);
    setIsDropdownOpen(false);
  };

  const LoginButton: React.FC<{ isMobile?: boolean }> = ({ isMobile = false }) => (
    <NavLink
      to="/login"
      className={`text-indigo-800 bg-white hover:bg-indigo-100 px-4 py-2 rounded-md text-base font-medium shadow-md transition duration-300 ease-in-out ${
        isMobile ? 'block w-full text-center' : ''
      }`}
      onClick={() => isMobile && setIsMenuOpen(false)}
      aria-label="Log in to your account"
    >
      Log in
    </NavLink>
  );

  return (
    <motion.nav 
      className="bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] shadow-lg sticky top-0 z-50"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <NavLink to="/" className="text-white text-xl font-bold">
              Inktuitive Email
            </NavLink>
          </div>
          <div className="hidden md:flex md:items-center md:justify-center flex-grow">
            <div className="flex items-baseline space-x-4">
              {navItems.map((item) => (
                <NavLink
                  key={item.to}
                  to={item.to}
                  style={({ isActive }) => navLinkStyles(isActive)}
                  className="text-white hover:text-white"
                >
                  {item.label}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="hidden md:flex md:items-center space-x-4">
            {user?.role === UserRole.ADMIN && (
              <NavLink
                to="/admin"
                style={({ isActive }) => navLinkStyles(isActive)}
                className="text-white hover:text-white"
              >
                Admin Panel
              </NavLink>
            )}
            {user ? (
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center text-white hover:text-gray-200 focus:outline-none"
                >
                  <img
                    className="h-8 w-8 rounded-full"
                    src={`https://ui-avatars.com/api/?name=${user.email}&background=random`}
                    alt="User avatar"
                  />
                </button>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-gradient-to-r from-[#4f46e5] to-[#7c3aed] rounded-md shadow-lg py-1">
                    <button
                      onClick={() => handleDropdownItemClick('/support')}
                      className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-indigo-700"
                    >
                      Support
                    </button>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-indigo-700"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <LoginButton />
            )}
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-[#4f46e5] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              {isMenuOpen ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map((item) => (
              <NavLink
                key={item.to}
                to={item.to}
                style={({ isActive }) => navLinkStyles(isActive)}
                className="text-white hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                onClick={() => setIsMenuOpen(false)}
              >
                {item.label}
              </NavLink>
            ))}
            {user?.role === UserRole.ADMIN && (
              <NavLink
                to="/admin"
                style={({ isActive }) => navLinkStyles(isActive)}
                className="text-white hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                onClick={() => setIsMenuOpen(false)}
              >
                Admin Panel
              </NavLink>
            )}
            {user && (
              <NavLink
                to="/support"
                style={({ isActive }) => navLinkStyles(isActive)}
                className="text-white hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                onClick={() => setIsMenuOpen(false)}
              >
                Support
              </NavLink>
            )}
            {user ? (
              <button
                onClick={() => {
                  handleLogout();
                  setIsMenuOpen(false);
                }}
                className="text-white bg-[#4f46e5] hover:bg-[#4338ca] block w-full text-left px-3 py-2 rounded-md text-base font-medium"
              >
                Logout
              </button>
            ) : (
              <LoginButton isMobile={true} />
            )}
          </div>
        </div>
      )}
    </motion.nav>
  );
};

export default NavBar;
