import React, { useEffect } from "react";
import { motion } from "framer-motion";
import PageLayout from "../components/layout/PageLayout";
import {
  LightBulbIcon,
  UserGroupIcon,
  GlobeAltIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import EnvelopeMailBgImage from "../images/background/envelope-mail.png";

import ShayneMoorePhoto from "../images/about-people/Shayne-Moore-v2.png";
import JakeSheltonPhoto from "../images/about-people/Jake-Shelton-v2.png";
import DomEnglandPhoto from "../images/about-people/Dom-England.png";
import NickFauchellePhoto from "../images/about-people/Nick.png";
import JackAllanPhoto from "../images/about-people/Jack.png";
import DanielHurdPhoto from "../images/about-people/Daniel.png";

const AboutPage: React.FC = () => {
  const teamMembers = [
    { name: "Shayne Moore", role: "CEO & Founder", image: ShayneMoorePhoto },
    { name: "Jake Shelton", role: "COO", image: JakeSheltonPhoto },
    { name: "Dom England", role: "Lead Tech R&D", image: DomEnglandPhoto },
    {
      name: "Nick Fauchelle",
      role: "Senior Developer",
      image: NickFauchellePhoto,
    },
    { name: "Jack Allan", role: "Senior Developer", image: JackAllanPhoto },
    { name: "Daniel Hurd", role: "Senior Developer", image: DanielHurdPhoto },
  ];

  useEffect(() => {
    document.title = "About Us | Inktuitive Email";
  }, []);

  useEffect(() => {
    document.body.classList.add("scrollable-page");
    return () => {
      document.body.classList.remove("scrollable-page");
    };
  }, []);

  return (
    <PageLayout>
      {/* Hero Banner */}
      <motion.div
        className="relative bg-gradient-to-br from-indigo-600 to-purple-600 text-white py-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div
          className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat opacity-10"
          style={{
            backgroundImage:
              `url(${EnvelopeMailBgImage})`,
          }}
        ></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl mb-4">
            About Inktuitive Email
          </h1>
          <p className="text-xl max-w-3xl">
            Revolutionizing email management with AI-powered solutions. Discover
            our story, values, and the team behind Inktuitive Email.
          </p>
        </div>
      </motion.div>

      {/* Main Content */}
      <motion.div
        className="flex-grow bg-gradient-to-br from-indigo-50 to-purple-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="py-12 sm:py-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto w-full">
            {/* Our Story */}
            <motion.div
              className="mb-16"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <h2 className="text-3xl font-semibold mb-6 text-gray-800">
                Our Story
              </h2>
              <p className="text-gray-600 mb-4">
                Inktuitive Email began its journey in 2024 with a clear mission:
                to leverage AI technology to improve business outcomes by
                automating resource-heavy tasks. Our focus quickly turned to
                email management, recognizing it as a critical area where
                businesses could reclaim valuable time.
              </p>
              <p className="text-gray-600 mb-4">
                Born out of Mint Design, a successful marketing agency, we
                understood firsthand the challenges businesses face with
                communication overload. We set out to create a solution that
                would allow users to spend more time doing what they love in
                business, rather than being bogged down by inbox management.
              </p>
              <p className="text-gray-600">
                Today, Inktuitive Email stands at the forefront of AI-assisted
                communication, continually pushing the boundaries of what's
                possible. Our goal is to empower businesses by transforming
                email from a time-consuming chore into a streamlined, intuitive
                process that enhances productivity and fosters growth.
              </p>
            </motion.div>

            {/* Our Values */}
            <motion.div
              className="mb-16"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <h2 className="text-3xl font-semibold mb-6 text-gray-800">
                Our Values
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {[
                  {
                    title: "Innovation",
                    description:
                      "We constantly push the boundaries of AI technology to improve email management.",
                    icon: <LightBulbIcon className="h-8 w-8" />,
                  },
                  {
                    title: "User-Centric",
                    description:
                      "Our users are at the heart of everything we do. We design with empathy and purpose.",
                    icon: <UserGroupIcon className="h-8 w-8" />,
                  },
                  {
                    title: "Integrity",
                    description:
                      "We uphold the highest standards of data privacy and ethical AI development.",
                    icon: <GlobeAltIcon className="h-8 w-8" />,
                  },
                ].map((value, index) => (
                  <motion.div
                    key={index}
                    className="bg-white p-6 rounded-lg shadow-lg"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.5 + index * 0.1 }}
                  >
                    <div className="text-indigo-500 mb-4">{value.icon}</div>
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">
                      {value.title}
                    </h3>
                    <p className="text-gray-600">{value.description}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            {/* Our Team */}
            <motion.div
              className="mb-16"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <h2 className="text-3xl font-semibold mb-6 text-gray-800">
                Our Team
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 justify-items-center">
                {teamMembers.map((member, index) => (
                  <motion.div
                    key={index}
                    className="text-center w-full max-w-xs"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.7 + index * 0.1 }}
                  >
                    <div className="w-24 h-36 sm:w-28 sm:h-42 lg:w-32 lg:h-48 mx-auto mb-4 overflow-hidden">
                      <img
                        src={member.image}
                        alt={member.name}
                        className="w-full h-full object-cover rounded-[30px]"
                      />
                    </div>
                    <h3 className="text-lg font-semibold text-gray-800">
                      {member.name}
                    </h3>
                    <p className="text-sm text-gray-600">{member.role}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* CTA Section */}
      <motion.section
        className="py-20 bg-gradient-to-r from-indigo-600 to-purple-600 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-extrabold mb-6">
            Join Us on Our Journey
          </h2>
          <p className="text-xl mb-8">
            At Inktuitive Email, we're more than just a team – we're a community
            of innovators, dreamers, and problem-solvers. We're passionate about
            creating technology that makes a real difference in people's lives.
          </p>
          <div className="flex justify-center space-x-4">
            <a
              href="/contact"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-50 transition-colors duration-300"
            >
              Get in Touch
              <ArrowRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </a>
            <a
              href="/#register"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition-colors duration-300"
            >
              Register Interest
            </a>
          </div>
        </div>
      </motion.section>
    </PageLayout>
  );
};

export default AboutPage;
