import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
} from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { store } from "./store/store";
import App from "./App";
import "./index.css";

// Retrieve the Bugsnag API key from environment variables
const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY ?? "";
const isBugsnagEnabled = Boolean(bugsnagApiKey);

// Initialize ErrorBoundary as React.Fragment by default
let ErrorBoundary: BugsnagErrorBoundary = ({ children }) => <>{children}</>;

if (isBugsnagEnabled) {
  // Initialize Bugsnag with the React plugin
  Bugsnag.start({
    apiKey: bugsnagApiKey,
    plugins: [new BugsnagPluginReact(React)],
  });

  // Initialize Bugsnag Performance (if needed)
  BugsnagPerformance.start({ apiKey: bugsnagApiKey });

  // Get the Bugsnag React plugin
  const bugsnagPlugin = Bugsnag.getPlugin("react");

  if (bugsnagPlugin) {
    const consoleErr = console.error;
    console.error = (...args) => {
      Bugsnag.notify(...args);
      consoleErr(...args);
    };
    // Create the ErrorBoundary component
    ErrorBoundary = bugsnagPlugin.createErrorBoundary(React);
  }
} else {
  console.warn("BugSnag API Key not set.");
}

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

if (!googleClientId) {
  console.error("REACT_APP_GOOGLE_CLIENT_ID is not defined in the environment");
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Router>
        <GoogleOAuthProvider clientId={googleClientId || ""}>
          <App />
        </GoogleOAuthProvider>
      </Router>
    </Provider>
  </ErrorBoundary>
);
