import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { PlusIcon, XMarkIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  BusinessContextSettings as BusinessContextSettingsType,
  AssociatedBusiness,
} from '../../types/settings';
import { User } from '../../types/user';
import businessSettingsApi from '../../services/businessSettingsApi';
import { useToast } from '../../context/ToastContext'; // Import the useToast hook

interface BusinessContextSettingsProps {
  settings?: BusinessContextSettingsType;
  onSave: (updatedSettings: BusinessContextSettingsType) => Promise<void>;
  onDelete?: () => void; 
  organizationName: string;
  organizationDomain: string;
  organizationId: number;
  showUserManagement?: boolean;
  readOnly?: boolean;
}

const businessTypes = ['Parent Company', 'Child Company', 'Partner', 'Supplier', 'Client'];

const BusinessContextSettingsComponent: React.FC<BusinessContextSettingsProps> = ({
  settings,
  onSave,
  onDelete,
  organizationName,
  organizationDomain,
  organizationId,
  showUserManagement = false,
  readOnly = false,
}) => {
  const [currentSettings, setCurrentSettings] = useState<BusinessContextSettingsType | null>(settings || null);
  const [newOffering, setNewOffering] = useState('');
  const [newBusinessName, setNewBusinessName] = useState('');
  const [newBusinessType, setNewBusinessType] = useState(businessTypes[0]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [organizationUsers, setOrganizationUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<{ value: number; label: string } | null>(null);

  const { showToast } = useToast(); // Use the useToast hook

  useEffect(() => {
    const fetchBusinessContext = async () => {
      try {
        const businessContext = await businessSettingsApi.getOrganizationBusinessContext(organizationId);
        setCurrentSettings(businessContext);
        showToast('Business context settings loaded successfully', 'success');
      } catch (error) {
        console.error('Error fetching business context settings:', error);
        setError('No business context settings found for this organization.');
        showToast('Failed to load business context settings', 'error');
      } finally {
        setLoading(false);
      }
    };

    if (!settings && organizationId) {
      fetchBusinessContext();
    } else {
      setLoading(false);
    }
  }, [settings, organizationId, showToast]);

  const fetchAllUsers = useCallback(async () => {
    try {
      const users = await businessSettingsApi.getUsers();
      setAllUsers(users);
    } catch (err) {
      console.error('Error fetching users:', err);
      showToast('Failed to fetch users', 'error');
    }
  }, [showToast]);

  const fetchOrganizationUsers = useCallback(async (orgId: number) => {
    try {
      const users = await businessSettingsApi.getUsersForOrganization(orgId);
      setOrganizationUsers(users);
    } catch (err) {
      console.error('Error fetching organization users:', err);
      showToast('Failed to fetch organization users', 'error');
    }
  }, [showToast]);

  useEffect(() => {
    if (showUserManagement) {
      fetchAllUsers();
      fetchOrganizationUsers(organizationId);
    }
  }, [organizationId, showUserManagement, fetchAllUsers, fetchOrganizationUsers]);

  // const handleSave = async () => {
  //   if (currentSettings && !readOnly) {
  //     try {
  //       await onSave(currentSettings);
  //       setError(null);
  //       showToast('Business context settings saved successfully', 'success');
  //     } catch (err) {
  //       setError('Failed to save business context settings');
  //       console.error('Error saving settings:', err);
  //       showToast('Failed to save business context settings', 'error');
  //     }
  //   }
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (readOnly) return;
    const { name, value } = e.target;
    setCurrentSettings((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const handleAddOffering = () => {
    if (!readOnly && newOffering && currentSettings) {
      setCurrentSettings((prev) => ({
        ...prev!,
        key_offerings: [...prev!.key_offerings, newOffering],
      }));
      setNewOffering('');
      showToast('New offering added', 'success');
    }
  };

  const handleRemoveOffering = (index: number) => {
    if (!readOnly) {
      setCurrentSettings((prev) => ({
        ...prev!,
        key_offerings: prev!.key_offerings.filter((_, i) => i !== index),
      }));
      showToast('Offering removed', 'info');
    }
  };

  const handleAddAssociatedBusiness = () => {
    if (!readOnly && newBusinessName && currentSettings) {
      const newBusiness: AssociatedBusiness = {
        name: newBusinessName,
        type: newBusinessType,
      };
      setCurrentSettings((prev) => ({
        ...prev!,
        associated_businesses: [...prev!.associated_businesses, newBusiness],
      }));
      setNewBusinessName('');
      setNewBusinessType(businessTypes[0]);
      showToast('Associated business added', 'success');
    }
  };

  const handleRemoveAssociatedBusiness = (index: number) => {
    if (!readOnly) {
      setCurrentSettings((prev) => ({
        ...prev!,
        associated_businesses: prev!.associated_businesses.filter((_, i) => i !== index),
      }));
      showToast('Associated business removed', 'info');
    }
  };

  const handleAddUserToOrganization = async () => {
    if (selectedUser && currentSettings && !readOnly) {
      try {
        await businessSettingsApi.addUserToOrganization(currentSettings.organization_id, selectedUser.value);
        fetchOrganizationUsers(currentSettings.organization_id);
        setSelectedUser(null);
        showToast('User added to organization', 'success');
      } catch (error) {
        setError('Error adding user to organization');
        showToast('Failed to add user to organization', 'error');
      }
    }
  };

  const handleRemoveUserFromOrganization = async (userId: number) => {
    if (!readOnly && currentSettings) {
      try {
        await businessSettingsApi.removeUserFromOrganization(currentSettings.organization_id, userId);
        fetchOrganizationUsers(currentSettings.organization_id);
        showToast('User removed from organization', 'info');
      } catch (error) {
        setError('Error removing user from organization');
        showToast('Failed to remove user from organization', 'error');
      }
    }
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (onDelete) {
      onDelete();
      showToast('Organization deleted', 'info');
    }
    setIsDeleteModalOpen(false);
  };

  if (loading) {
    return <div>Loading business context settings...</div>;
  }

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {/* Organization Name and Domain - Read-Only */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Organization Name</label>
          <input
            type="text"
            value={organizationName || 'Unknown'}
            readOnly
            className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Organization Domain</label>
          <input
            type="text"
            value={organizationDomain || 'Unknown'}
            readOnly
            className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-gray-700"
          />
        </div>
      </div>

      {/* Industry */}
      <div>
        <label htmlFor="industry" className="block text-sm font-medium text-gray-700 mb-1">
          Industry
        </label>
        <input
          type="text"
          id="industry"
          name="industry"
          value={currentSettings?.industry || ''}
          onChange={handleInputChange}
          readOnly={readOnly}
          className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
            readOnly ? 'bg-gray-100 text-gray-700' : 'focus:ring-indigo-500 focus:border-indigo-500'
          }`}
        />
      </div>

      {/* Key Offerings */}
      <div>
        <label htmlFor="key_offerings" className="block text-sm font-medium text-gray-700 mb-2">
          Key Offerings
        </label>
        <ul className="space-y-2 mb-2">
          {currentSettings?.key_offerings.map((offering, index) => (
            <li key={index} className="flex items-center bg-gray-50 px-3 py-2 rounded-md">
              <span className="flex-grow">{offering}</span>
              {!readOnly && (
                <button
                  type="button"
                  onClick={() => handleRemoveOffering(index)}
                  className="text-red-600 hover:text-red-800"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              )}
            </li>
          ))}
        </ul>

        {!readOnly && (
          <div className="flex">
            <input
              type="text"
              id="key_offerings"
              value={newOffering}
              onChange={(e) => setNewOffering(e.target.value)}
              className="flex-grow px-3 py-2 border border-gray-300 rounded-l-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Add new offering"
            />
            <button
              type="button"
              onClick={handleAddOffering}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-r-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon className="h-5 w-5" />
            </button>
          </div>
        )}
      </div>

      {/* Associated Businesses */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">Associated Businesses</label>
        <ul className="space-y-2 mb-2">
          {currentSettings?.associated_businesses.map((business, index) => (
            <li key={index} className="flex items-center bg-gray-50 px-3 py-2 rounded-md">
              <span className="flex-grow">
                {business.name} ({business.type})
              </span>
              {!readOnly && (
                <button
                  type="button"
                  onClick={() => handleRemoveAssociatedBusiness(index)}
                  className="text-red-600 hover:text-red-800"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              )}
            </li>
          ))}
        </ul>

        {!readOnly && (
          <div className="space-y-2">
            <div className="flex space-x-2">
              <input
                type="text"
                value={newBusinessName}
                onChange={(e) => setNewBusinessName(e.target.value)}
                placeholder="Business Name"
                className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              <select
                value={newBusinessType}
                onChange={(e) => setNewBusinessType(e.target.value)}
                className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                {businessTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="button"
              onClick={handleAddAssociatedBusiness}
              className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Associated Business
            </button>
          </div>
        )}
      </div>

      {/* Additional Context */}
      <div>
        <label htmlFor="additional_context" className="block text-sm font-medium text-gray-700 mb-1">
          Additional Context
        </label>
        <textarea
          id="additional_context"
          name="additional_context"
          value={currentSettings?.additional_context || ''}
          onChange={handleInputChange}
          rows={4}
          readOnly={readOnly}
          className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
            readOnly ? 'bg-gray-100 text-gray-700' : 'focus:ring-indigo-500 focus:border-indigo-500'
          }`}
        />
      </div>

      {/* User Management */}
      {showUserManagement && !readOnly && (
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-4">Manage Users in Organization</h3>
          <Select
            options={allUsers.map((user) => ({ value: user.id, label: user.email }))}
            value={selectedUser}
            onChange={setSelectedUser}
            placeholder="Select a user to add"
            className="mb-4"
          />
          <button
            type="button"
            onClick={handleAddUserToOrganization}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add User
          </button>

          <h4 className="text-md font-semibold mt-6 mb-2">Users in Organization</h4>
          <ul className="space-y-2">
            {organizationUsers.map((user) => (
              <li key={user.id} className="flex justify-between items-center bg-gray-50 px-3 py-2 rounded-md">
                <span>{user.email}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveUserFromOrganization(user.id)}
                  className="text-red-600 hover:text-red-800"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

{/* Action Buttons (Delete / Save) */}
{!readOnly && (
  <div className="flex justify-between items-center mt-8">
    {/* Trash Button on the left */}
    <button
      type="button"
      onClick={handleDelete}
      className="text-red-600 hover:text-red-800"
    >
      <TrashIcon className="h-6 w-6" />
    </button>

    {/* Save Button on the right */}
    <button
      type="button"
      onClick={async () => {
        if (currentSettings) await onSave(currentSettings);
      }}
      className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      Save Changes
    </button>
  </div>
)}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="text-xl font-bold mb-4">Delete Organization</h3>
            <p className="mb-6">Are you sure you want to delete this organization?</p>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-300 rounded-md mr-2 hover:bg-gray-400 transition duration-300"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300"
                onClick={confirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessContextSettingsComponent;
