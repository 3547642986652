import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import PageLayout from '../components/layout/PageLayout';
import { ShieldCheckIcon, LockClosedIcon, DocumentTextIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import EnvelopeMailBgImage from "../images/background/envelope-mail.png";

const PrivacyPolicyPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Privacy Policy | Inktuitive Email';
  }, []);

  useEffect(() => {
    document.body.classList.add('scrollable-page');
    return () => {
      document.body.classList.remove('scrollable-page');
    };
  }, []);

  const policyPoints = [
    {
      title: 'Data Collection',
      content: 'We collect information that you provide directly to us, such as when you create an account, use our services, or contact us for support.',
      icon: <DocumentTextIcon className="h-8 w-8" />
    },
    {
      title: 'Data Usage',
      content: 'We use your personal information to provide, maintain, and improve our services, as well as to communicate with you about your account and our products.',
      icon: <LockClosedIcon className="h-8 w-8" />
    },
    {
      title: 'Data Protection',
      content: 'We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.',
      icon: <ShieldCheckIcon className="h-8 w-8" />
    },
  ];

  return (
    <PageLayout>
      {/* Hero Banner */}
      <motion.div
        className="relative bg-gradient-to-br from-indigo-600 to-purple-600 text-white py-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div 
          className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat opacity-10"
          style={{
            backgroundImage: `url(${EnvelopeMailBgImage})`,
          }}
        ></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl mb-4">
            Privacy Policy
          </h1>
          <p className="text-xl max-w-3xl">
            At Inktuitive Email, we value your privacy and are committed to protecting your personal information.
          </p>
        </div>
      </motion.div>

      {/* Main Content */}
      <motion.div
        className="flex-grow bg-gradient-to-br from-indigo-50 to-purple-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="py-12 sm:py-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto w-full">
            {/* Introduction */}
            <motion.div
              className="mb-16"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <h2 className="text-3xl font-semibold mb-6 text-gray-800">Our Commitment to Privacy</h2>
              <p className="text-gray-600 mb-4">
                Inktuitive Email is dedicated to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices concerning the collection, use, and protection of your data when you use our services.
              </p>
              <p className="text-gray-600">
                We encourage you to read this policy carefully to understand how we handle your information and your rights regarding your data.
              </p>
            </motion.div>
            
            {/* Policy Points */}
            <motion.div
              className="mb-16"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <h2 className="text-3xl font-semibold mb-6 text-gray-800">Key Policy Points</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {policyPoints.map((point, index) => (
                  <motion.div
                    key={index}
                    className="bg-white p-6 rounded-lg shadow-lg"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.5 + index * 0.1 }}
                  >
                    <div className="text-indigo-500 mb-4">{point.icon}</div>
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">{point.title}</h3>
                    <p className="text-gray-600">{point.content}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>
            
            {/* Full Privacy Policy */}
            <motion.div
              className="mb-16"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <h2 className="text-3xl font-semibold mb-6 text-gray-800">Full Privacy Policy</h2>
                <div className="bg-white p-8 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold mb-4 text-gray-800">1. Introduction</h3>
                <p className="text-gray-600 mb-4">
                    Welcome to Inktuitive Email, a service provided by Inktuitive ("we," "us," or "our"). Inktuitive is affiliated with Mint Design (sister company) and Nexmind (parent company). We are committed to protecting your privacy and ensuring that your personal information is handled securely and responsibly. This Privacy Policy explains how we collect, use, share, and protect your information when you use our web application ("App") that utilizes artificial intelligence (AI) to sort your emails into user-defined categories and draft emails when you connect your Gmail account. Microsoft Outlook support will be added in the future.
                </p>
                <p className="text-gray-600 mb-4">
                    By using our App, you agree to the collection and use of information in accordance with this Privacy Policy. Please note that while Inktuitive Email is our current offering, we may develop additional services in the future, which will be covered by an updated privacy policy.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">2. Information We Collect</h3>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">a. Personal Information</h4>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Account Registration: When you create an account, we collect personal information such as your name and email address.</li>
                    <li>Authentication Data: To connect your email account, we use OAuth 2.0 authentication protocols. This process collects access tokens and refresh tokens, allowing us to access your email data with your permission.</li>
                    <li>User-Provided Context: We collect personal context information that you provide to enhance the functionality of our service.</li>
                    <li>Organizational Affiliations: Information about your organizational affiliations may be collected to improve service personalization.</li>
                </ul>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">b. Email Data</h4>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Email Content: With your explicit consent, we access and process the content of your emails, including the body, subject lines, sender and recipient information, attachments, and other metadata.</li>
                    <li>Email Categories: We collect and store information about the categories you define for email sorting.</li>
                    <li>Response Settings: Your preferences for email response settings are collected and stored.</li>
                    <li>Processing Records/Logs: We collect logs related to the processing of your emails, which may include timestamps, processing status, and error reports. These logs do not contain the actual content of your emails.</li>
                </ul>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">c. Usage Data</h4>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Technical Information: We collect information such as your IP address, browser type, operating system, and device identifiers.</li>
                    <li>Cookies and Similar Technologies: We use cookies and similar tracking technologies to enhance your experience on our App.</li>
                </ul>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">3. How We Use Your Information</h3>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">a. Providing and Improving Our Services</h4>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Email Sorting and Drafting: To sort your emails into user-defined categories and draft responses using AI.</li>
                    <li>User Settings Configuration: To enable you to configure and manage your user settings through the App's frontend.</li>
                    <li>Service Optimization: To monitor and analyze usage and trends to improve user experience and the functionality of our App.</li>
                </ul>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">b. AI Processing</h4>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Third-Party AI APIs: We send the content of your emails to third-party AI service providers (OpenAI and Anthropic) for processing. These providers are contractually obligated to protect your data and use it only for the purpose of providing services to us.</li>
                    <li>Automated Decision-Making: We use AI to make automated decisions about email sorting and response drafting, utilizing user-provided context and settings in this process.</li>
                </ul>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">c. Communication</h4>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Service-Related Communications: To send you updates, security alerts, and support messages.</li>
                    <li>Marketing Communications: To send you promotional materials, if you have opted in to receive them.</li>
                </ul>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">d. Legal and Compliance</h4>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Compliance with Laws: To comply with legal obligations, respond to legal requests, and prevent harm.</li>
                </ul>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">4. How We Share Your Information</h3>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">a. Third-Party Service Providers</h4>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>AI Service Providers: We share your email content with OpenAI and Anthropic for AI processing. They are required to handle your data in accordance with their privacy policies and applicable laws.</li>
                    <li>Cloud Service Providers: We may store data on cloud servers provided by third parties who are obligated to protect your information.</li>
                    <li>Email Service Providers: We use Postmark for handling certain email-related functions.</li>
                </ul>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">b. Legal Obligations</h4>
                <p className="text-gray-600 mb-4">
                    Compliance and Protection: We may disclose your information if required by law or in response to valid legal requests.
                </p>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">c. Business Transfers</h4>
                <p className="text-gray-600 mb-4">
                    Merger or Acquisition: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the acquiring entity.
                </p>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">d. With Your Consent</h4>
                <p className="text-gray-600 mb-4">
                    Third-Party Sharing: We may share your information with third parties when you consent to such sharing.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">5. Data Security</h3>
                <p className="text-gray-600 mb-4">
                    We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include:
                </p>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Encryption: We use Let's Encrypt for SSL/TLS encryption.</li>
                    <li>Email Content: We do not store the content of your emails on our servers beyond the time required for processing.</li>
                </ul>
                <p className="text-gray-600 mb-4">
                    While we strive to use commercially acceptable means to protect your personal information, no method of transmission over the internet or electronic storage is 100% secure.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">6. Data Retention</h3>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Email Content: We do not store the content of your emails on our servers beyond the time required for processing.</li>
                    <li>Processing Records/Logs: We retain processing records and logs for 30 days, which are used for service improvement and troubleshooting.</li>
                    <li>User Settings: User settings and configuration data are retained indefinitely unless the user requests data removal.</li>
                </ul>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">7. Your Rights and Choices</h3>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">a. Access and Control</h4>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Account Information: You can access and update your account information through the App.</li>
                    <li>Revocation of Access: You can revoke our access to your email account at any time through your email provider's settings.</li>
                </ul>
                <h4 className="text-xl font-semibold mb-2 text-gray-700">b. Data Subject Rights</h4>
                <p className="text-gray-600 mb-4">
                    Depending on your jurisdiction, you may have the right to:
                </p>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Access: Request a copy of the personal information we hold about you.</li>
                    <li>Correction: Request correction of inaccurate or incomplete information.</li>
                    <li>Deletion: Request deletion of your personal information.</li>
                    <li>Objection: Object to processing of your personal information.</li>
                    <li>Restriction: Request restriction of processing.</li>
                    <li>Data Portability: Request transfer of your personal information to another service provider.</li>
                    <li>Withdraw Consent: Withdraw your consent at any time.</li>
                </ul>
                <p className="text-gray-600 mb-4">
                    To exercise these rights, please contact us via the support dashboard in the App. Please note that these rights may be limited to the data we hold, excluding certain logs and processing records.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">8. Children's Privacy</h3>
                <p className="text-gray-600 mb-4">
                    Our App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. While we do not implement age verification measures directly, the OAuth 2.0 providers (Google or Microsoft) may have such measures in place for account creation.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">9. International Data Transfers</h3>
                <p className="text-gray-600 mb-4">
                    Your information may be transferred to and maintained on servers located outside of your country or jurisdiction, particularly in the United States where our AI API providers are located. We take steps to ensure that your data is treated securely and in accordance with this Privacy Policy.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">10. Compliance with Third-Party API Policies</h3>
                <p className="text-gray-600 mb-4">
                    Our use of information received from Google APIs adheres to the Google API Services User Data Policy, including the Limited Use requirements. We also comply with the applicable policies of other API providers we use, including OpenAI and Anthropic.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">11. Changes to This Privacy Policy</h3>
                <p className="text-gray-600 mb-4">
                    We reserve the right to update this Privacy Policy at any time. We may or may not notify users of changes by email. We encourage you to review this Privacy Policy periodically for any changes.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">12. Contact Us</h3>
                <p className="text-gray-600 mb-4">
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us through the support dashboard in the App.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">13. Jurisdiction-Specific Provisions</h3>
                <p className="text-gray-600 mb-4">
                    While we primarily aim to service New Zealand, users from other jurisdictions may have additional rights under their local laws. If you believe specific privacy regulations apply to you, please contact us for clarification.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">14. Data Controller and Processor</h3>
                <p className="text-gray-600 mb-4">
                    Inktuitive generally acts as the data controller for personal information collected through the App. In some scenarios, we may act as a data processor, particularly when processing data through third-party AI services.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">15. Third-Party Links</h3>
                <p className="text-gray-600 mb-4">
                    Our App may contain links to other websites or services that are not operated by us. We are not responsible for the content or privacy practices of these third-party sites.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">16. Acceptance of This Policy</h3>
                <p className="text-gray-600 mb-4">
                    By using our App, you signify your acceptance of this Privacy Policy. If you do not agree with the terms, please do not use our App.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">17. Governing Law</h3>
                <p className="text-gray-600 mb-4">
                    This Privacy Policy shall be governed and construed in accordance with the laws of New Zealand, without regard to its conflict of law provisions.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">18. Language</h3>
                <p className="text-gray-600 mb-4">
                    This Privacy Policy is provided in English and will not be translated into other languages at this time.
                </p>

                <p className="text-gray-600 mt-8">
                    Thank you for trusting Inktuitive Email with your personal information. We are dedicated to protecting your privacy and delivering a secure experience.
                </p>
                </div>
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* CTA Section */}
      <motion.section
        className="py-20 bg-gradient-to-r from-indigo-600 to-purple-600 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-extrabold mb-6">Have Questions?</h2>
          <p className="text-xl mb-8">
            If you have any questions about our Privacy Policy or how we handle your data, please don't hesitate to contact us.
          </p>
          <div className="flex justify-center space-x-4">
            <a
              href="/contact"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-50 transition-colors duration-300"
            >
              Contact Us
              <ArrowRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </a>
          </div>
        </div>
      </motion.section>
    </PageLayout>
  );
};

export default PrivacyPolicyPage;