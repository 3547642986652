import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  emailSettingsApi,
  Category,
  EmailSortingRule,
  EmailCategoryConfigUpdate,
  // Remove the RuleType import from here
} from '../../services/emailSettingsApi';
import adminApi from '../../services/adminApi';
import ConfirmationModal from './ConfirmationModal';
import { UserRole } from '../../types/user';
import { useToast } from '../../context/ToastContext';
import CategoryItem from './CategoryItem';
import SuggestedCategories from './SuggestedCategories';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { InformationCircleIcon, SparklesIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

// Define the props for the EmailSortingSettings component
interface EmailSortingSettingsProps {
  userId: number;
}

// Main component for managing email sorting settings
const EmailSortingSettings: React.FC<EmailSortingSettingsProps> = ({ userId }) => {
  // State to hold the list of email categories
  const [categories, setCategories] = useState<Category[]>([]);
  // State for the new category input field
  const [newCategory, setNewCategory] = useState('');
  // State to hold Gmail labels fetched from the API
  const [gmailLabels, setGmailLabels] = useState<string[]>([]);
  // Loading state to indicate data fetching
  const [isLoading, setIsLoading] = useState(true);
  // State to hold any error messages
  const [error, setError] = useState<string | null>(null);
  // State to control the visibility of the confirmation modal
  const [showModal, setShowModal] = useState(false);
  // State to store the name of the category to be removed
  const [categoryToRemove, setCategoryToRemove] = useState<string | null>(null);
  // State to track which category is expanded to show its rules
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  // State to indicate if a deletion operation is in progress
  const [isDeleting, setIsDeleting] = useState(false);
  // State to track the ID of the category being processed for deletion
  const [processingCategoryId, setProcessingCategoryId] = useState<string | null>(null);
  // State to track the Gmail label status for each category
  const [categoryLabelStatus, setCategoryLabelStatus] = useState<Record<string, 'pending' | 'found' | 'not_found'>>({});

  const [isHowItWorksExpanded, setIsHowItWorksExpanded] = useState(false);

  // Hook to display toast notifications
  const { showToast } = useToast();

  // Effect to add a CSS class for scrollable pages and clean up on unmount
  useEffect(() => {
    document.body.classList.add('scrollable-page');
    return () => {
      document.body.classList.remove('scrollable-page');
    };
  }, []);

  // Retrieve the current user from the Redux store
  const currentUser = useSelector((state: RootState) => state.auth.user);
  // Determine if the current user has admin privileges
  const isAdmin = useMemo(() => currentUser?.role === UserRole.ADMIN, [currentUser]);

  // Effect to fetch email categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        let fetchedCategories;
        if (isAdmin) {
          fetchedCategories = await adminApi.getEmailCategories(userId);
        } else {
          fetchedCategories = await emailSettingsApi.getEmailCategories();
        }

        const categoriesWithRules = await Promise.all(
          fetchedCategories.map(async (category) => {
            if (!category.rules || category.rules.length === 0) {
              let rules;
              if (isAdmin) {
                rules = await adminApi.getCategoryRules(userId, encodeURIComponent(category.name));
              } else {
                rules = await emailSettingsApi.getCategoryRules(encodeURIComponent(category.name));
              }
              return { ...category, rules };
            }
            return category;
          })
        );

        setCategories(categoriesWithRules);
        setError(null);
      } catch (err) {
        console.error('Error fetching email categories:', err);
        setError('Failed to fetch email categories');
        showToast('Failed to load email categories', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, [userId, isAdmin, showToast]);

  // Effect to fetch Gmail labels on component mount
  useEffect(() => {
    const fetchGmailLabels = async () => {
      try {
        const labels = isAdmin
          ? await adminApi.getGmailLabels(userId)
          : await emailSettingsApi.getGmailLabels();
        setGmailLabels(labels);
        showToast('Gmail labels loaded successfully', 'success');
      } catch (err) {
        console.error('Error fetching Gmail labels:', err);
        setError('Failed to fetch Gmail labels');
        showToast('Failed to load Gmail labels', 'error');
      }
    };

    fetchGmailLabels();
  }, [userId, isAdmin, showToast]);

  // Effect to update category label status whenever categories or Gmail labels change
  useEffect(() => {
    const updateCategoryLabelStatus = () => {
      const newStatus: Record<string, 'found' | 'not_found'> = {};
      categories.forEach(category => {
        newStatus[category.name] = gmailLabels.includes(category.name) ? 'found' : 'not_found';
      });
      setCategoryLabelStatus(newStatus);
      console.log('Updated categoryLabelStatus:', newStatus);
    };

    if (categories.length > 0 && gmailLabels.length > 0) {
      updateCategoryLabelStatus();
    }
  }, [categories, gmailLabels]);

  // Function to refresh the list of Gmail labels
  const refreshGmailLabels = async () => {
    try {
      const labels = isAdmin
        ? await adminApi.getGmailLabels(userId)
        : await emailSettingsApi.getGmailLabels();
      setGmailLabels(labels);
    } catch (err) {
      console.error('Error refreshing Gmail labels:', err);
      setError('Failed to refresh Gmail labels');
      showToast('Failed to refresh Gmail labels', 'error');
    }
  };

  // Handler to add a new category
  const handleAddCategory = async () => {
    // Validate the new category name
    if (newCategory && categories.length < 10 && !categories.some(cat => cat.name === newCategory)) {
      try {
        // Check if the new category name exists in Gmail labels
        const isGmailLabel = gmailLabels.includes(newCategory);
        const newCat: Category = {
          name: newCategory,
          is_app_created: !isGmailLabel, // Determine if the category is app-created
          rules: [],
          response_required: true,
        };
        // Update the categories state with the new category
        await updateCategories([...categories, newCat]);
        setCategoryLabelStatus(prev => ({ ...prev, [newCategory]: 'pending' }));
        // Reset the new category input field
        setNewCategory('');
        if (!isGmailLabel) {
          // Create a Gmail label if the category is not from Gmail
          const result = isAdmin
            ? await adminApi.createGmailLabel(newCategory, userId)
            : await emailSettingsApi.createGmailLabel(newCategory);
          if (result.message.includes('already exists')) {
            console.log(result.message);
          }
        }
        // Refresh the list of Gmail labels after adding a new category
        await refreshGmailLabels();
      } catch (err) {
        console.error('Error adding category:', err);
        setError('Failed to add category. It might already exist in Gmail.');
        showToast('Failed to add category', 'error');
      }
    }
  };

  // Handler for clicking on a suggested category to add it to the user's category list
  const handleSuggestedCategoryClick = async (category: string) => {
    // Ensure the category list doesn't exceed 10 and the category isn't already added
    if (categories.length < 10 && !categories.some((c) => c.name === category)) {
      try {
        const newCat: Category = {
          name: category,
          is_app_created: true, // Mark as app-created since it's a suggested category
          rules: [],
          response_required: true,
        };
        // Update the categories state with the new suggested category
        await updateCategories([...categories, newCat]);
        // Create the corresponding Gmail label
        if (isAdmin) {
          await adminApi.createGmailLabel(category, userId);
        } else {
          await emailSettingsApi.createGmailLabel(category);
        }
        // Refresh the Gmail labels to include the newly added label
        await refreshGmailLabels();
      } catch (err) {
        console.error('Error adding suggested category:', err);
        setError('Failed to add suggested category.');
        showToast('Failed to add suggested category', 'error');
      }
    }
  };

  // Handler to initiate the removal of a category by showing the confirmation modal
  const handleRemoveCategory = (categoryName: string) => {
    setCategoryToRemove(categoryName);
    setProcessingCategoryId(categoryName);
    setShowModal(true);
  };

  // Handler to confirm and execute the removal of a category
  const confirmRemoveCategory = async (option: string) => {
    if (categoryToRemove) {
      try {
        setIsDeleting(true);
        setIsLoading(true);

        // Remove the category using the appropriate API based on user role
        const result = isAdmin
          ? await adminApi.removeEmailCategory(userId, encodeURIComponent(categoryToRemove), option === 'both')
          : await emailSettingsApi.removeEmailCategory(encodeURIComponent(categoryToRemove), option === 'both');

        if (result.success) {
          // Update the categories state by removing the deleted category
          setCategories((prevCategories) =>
            prevCategories.filter((cat) => cat.name !== categoryToRemove)
          );
          setError(null);

          // If the category was removed from both Gmail and sorting, refresh the Gmail labels
          if (option === 'both') {
            await refreshGmailLabels();
          }
          showToast(`Category "${categoryToRemove}" removed successfully`, 'success');
        } else {
          setError(result.message);
          showToast(result.message, 'error');
        }
      } catch (err) {
        console.error('Error removing category:', err);
        setError('Failed to remove category. Please try again.');
        showToast('Failed to remove category', 'error');
      } finally {
        setIsDeleting(false);
        setIsLoading(false);
        setShowModal(false);
        setCategoryToRemove(null);
      }
    }
  };

  // Function to update categories by sending the updated list to the API
  const updateCategories = async (categoriesToSave: Category[]) => {
    try {
      setIsLoading(true);
      // Prepare the configuration update payload
      const configUpdate: EmailCategoryConfigUpdate = {
        categories: categoriesToSave.map((cat) => ({
          name: cat.name,
          is_app_created: cat.is_app_created,
          response_required: cat.response_required,
        })),
        rules: categoriesToSave.flatMap((cat) => cat.rules || []),
      };

      // Send the update to the appropriate API based on user role
      if (isAdmin) {
        await adminApi.updateEmailCategories(configUpdate, userId);
      } else {
        await emailSettingsApi.updateEmailCategories(configUpdate);
      }
      // Update the local categories state
      setCategories(categoriesToSave);
      setError(null);
      showToast('Categories updated successfully', 'success');
    } catch (err) {
      console.error('Error saving categories:', err);
      setError('Failed to save email categories');
      showToast('Failed to update categories', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // Handler to toggle the 'response_required' flag for a category
  const handleToggleResponseRequired = async (index: number) => {
    const updatedCategories = [...categories];
    const category = updatedCategories[index];
    const newResponseRequired = !category.response_required;
    
    // Check if the label exists in Gmail
    if (categoryLabelStatus[category.name] === 'not_found') {
      showToast(`Cannot update "${category.name}". Label not found in Gmail.`, 'error');
      return;
    }

    updatedCategories[index] = {
      ...category,
      response_required: newResponseRequired,
    };

    try {
      // Update the categories with the toggled flag
      await updateCategories(updatedCategories);
      showToast(`Priority Inbox & Responses toggled for "${category.name}"`, 'info');
    } catch (error) {
      console.error('Error updating category:', error);
      showToast(`Failed to update "${category.name}". Please try again.`, 'error');
    }
  };

  // Handler to add a new rule to a specific category
  const handleAddRule = async (categoryName: string, rule: Partial<EmailSortingRule>) => {
    try {
      // Construct the new rule object
      const ruleToAdd: EmailSortingRule = {
        rule_type: rule.rule_type!,
        rule_data: rule.rule_data!, // This should now contain the correct value
        description: rule.description || `${rule.rule_type}: ${rule.rule_data?.value}`,
        category_name: categoryName,
      };

      // Add the rule using the appropriate API based on user role
      const result = isAdmin
        ? await adminApi.addRuleToCategory(userId, encodeURIComponent(categoryName), ruleToAdd)
        : await emailSettingsApi.addRuleToCategory(encodeURIComponent(categoryName), ruleToAdd);

      // Update the local categories state with the newly added rule
      const updatedCategories = categories.map((cat) => {
        if (cat.name === categoryName) {
          return {
            ...cat,
            rules: [...(cat.rules || []), result],
          };
        }
        return cat;
      });
      setCategories(updatedCategories);
      showToast(`Rule added to category "${categoryName}"`, 'success');
    } catch (err) {
      console.error('Error adding rule:', err);
      setError('Failed to add rule. Please try again.');
      showToast('Failed to add rule', 'error');
    }
  };

  // Handler to remove a rule from a specific category
  const handleRemoveRule = async (categoryName: string, ruleId: number) => {
    try {
      setIsDeleting(true);
      setProcessingCategoryId(categoryName);
      setIsLoading(true);

      const encodedCategory = encodeURIComponent(categoryName);
      // Remove the rule using the appropriate API based on user role
      if (isAdmin) {
        await adminApi.removeRuleFromCategory(userId, encodedCategory, ruleId);
      } else {
        await emailSettingsApi.removeRuleFromCategory(encodedCategory, ruleId);
      }

      // Update the local categories state by removing the deleted rule
      const updatedCategories = categories.map((cat) =>
        cat.name === categoryName
          ? { ...cat, rules: cat.rules?.filter((rule) => rule.id !== ruleId) }
          : cat
      );
      setCategories(updatedCategories);
      showToast(`Rule removed from category "${categoryName}"`, 'success');
    } catch (err) {
      console.error('Error removing rule:', err);
      setError('Failed to remove rule. Please try again.');
      showToast('Failed to remove rule', 'error');
    } finally {
      setIsDeleting(false);
      setProcessingCategoryId(null);
      setIsLoading(false);
    }
  };

  // Handler for changes in the new category input field
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;
    setNewCategory(selectedValue);
    
    // Automatically add the category if it exists in Gmail labels and isn't already added
    if (gmailLabels.includes(selectedValue) && !categories.some(cat => cat.name === selectedValue)) {
      handleAddCategory();
    }
  };

  const handleUpdateCategories = async (updatedCategories: Category[]) => {
    try {
      setIsLoading(true);
      await updateCategories(updatedCategories);
      showToast('Categories reordered successfully', 'success');
    } catch (err) {
      console.error('Error reordering categories:', err);
      setError('Failed to reorder categories');
      showToast('Failed to reorder categories', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(categories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    handleUpdateCategories(items);
  };

  const handleUpdateCategory = (updatedCategory: Category) => {
    setCategories(prevCategories =>
      prevCategories.map(cat =>
        cat.name === updatedCategory.name ? updatedCategory : cat
      )
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-2 sm:p-4">
      <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">Email Sorting Categories</h2>
      
      <div className="mb-6">
        <div 
          className="flex justify-between items-center cursor-pointer p-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
          onClick={() => setIsHowItWorksExpanded(!isHowItWorksExpanded)}
        >
          <h3 className="text-sm font-medium text-gray-700">How It Works</h3>
          {isHowItWorksExpanded ? (
            <ChevronUpIcon className="h-4 w-4 text-gray-500" />
          ) : (
            <ChevronDownIcon className="h-4 w-4 text-gray-500" />
          )}
        </div>
        <div 
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            isHowItWorksExpanded ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
          }`}
        >
          <ul className="list-none space-y-2 text-xs sm:text-sm text-gray-600 mt-2 p-4 bg-white rounded-md shadow-sm">
            <li className="flex items-center">
              <span className="mr-2">📁</span>
              Setup Categories to organize your emails efficiently. 
              <span className="ml-2 tooltip" data-tip="Create up to 10 colour-coded categories. Green (labels exist in Gmail), Purple (App-created labels), Orange (label cannot be found in gmail - add again).">
                <InformationCircleIcon className="h-4 w-4 text-blue-500" />
              </span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">🏷️</span>
              Use suggested or custom categories
              <span className="ml-2 tooltip" data-tip="Choose from our suggestions or create your own">
                <InformationCircleIcon className="h-4 w-4 text-blue-500" />
              </span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">📥</span>
              "Keep in Inbox" option for priority emails
              <button className="ml-2 btn btn-xs btn-outline btn-primary">Keep in Inbox</button>
              <span className="ml-2 tooltip" data-tip="Emails remain visible in your main inbox, and drafts generated for these where appropriate">
                <InformationCircleIcon className="h-4 w-4 text-blue-500" />
              </span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">🤖</span>
              AI-powered personalized email drafts
              <span className="ml-2 tooltip" data-tip="AI-powered automatic draft creation for quick responses. Our AI learns from your previous email responses to create personalized drafts.">
                <InformationCircleIcon className="h-4 w-4 text-blue-500" />
              </span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">🔀</span>
              Drag and drop to reorder
              <span className="ml-2 tooltip" data-tip="Easily prioritize your categories">
                <InformationCircleIcon className="h-4 w-4 text-blue-500" />
              </span>
            </li>
            <li className="flex items-center">
              <span className="mr-2">✨</span>
              Generate rules automatically
              <button className="ml-2 btn btn-xs btn-outline btn-secondary">
                <SparklesIcon className="h-3 w-3 mr-1" />
                Generate Rules
              </button>
              <span className="ml-2 tooltip" data-tip="Use AI to create sorting rules based on your email patterns for categories">
                <InformationCircleIcon className="h-4 w-4 text-blue-500" />
              </span>
            </li>
          </ul>
        </div>
      </div>

      {error && <div className="alert alert-error mb-4 text-sm">{error}</div>}

      <SuggestedCategories categories={categories} onAddCategory={handleSuggestedCategoryClick} />

      <h3 className="text-lg font-semibold mb-2">Your Category List</h3>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <span className="loading loading-spinner loading-lg"></span>
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="categories">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef} className="space-y-2">
                {categories.map((category, index) => (
                  <CategoryItem
                    key={category.name}
                    category={category}
                    index={index}
                    onRemoveCategory={handleRemoveCategory}
                    onToggleResponseRequired={handleToggleResponseRequired}
                    onAddRule={handleAddRule}
                    onRemoveRule={handleRemoveRule}
                    labelStatus={categoryLabelStatus[category.name] || 'pending'}
                    isDeleting={isDeleting && processingCategoryId === category.name}
                    expandedCategory={expandedCategory}
                    setExpandedCategory={setExpandedCategory}
                    onUpdateCategory={handleUpdateCategory}
                    userId={userId}
                    isAdmin={isAdmin}
                  />
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {categories.length < 10 && (
        <div className="mt-4 flex flex-col sm:flex-row">
          <input
            type="text"
            value={newCategory}
            onChange={handleInputChange}
            placeholder="New category name"
            className="input input-bordered w-full sm:flex-grow mb-2 sm:mb-0 sm:mr-2 text-xs sm:text-sm"
            list="gmail-labels"
          />
          <datalist id="gmail-labels">
            {gmailLabels
              .filter(label => !categories.some(cat => cat.name === label))
              .map((label) => (
                <option key={label} value={label} />
              ))}
          </datalist>
          <button onClick={handleAddCategory} className="btn btn-primary w-full sm:w-auto text-xs sm:text-sm">
            Add Category
          </button>
        </div>
      )}

      <ConfirmationModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={confirmRemoveCategory}
        title="Remove Category"
        message={`Are you sure you want to remove the category "${categoryToRemove}"?`}
        options={[
          { value: 'both', label: 'Remove from Gmail and sorting categories' },
          { value: 'app_only', label: 'Remove from sorting categories only' },
        ]}
      />
    </div>
  );
};

export default EmailSortingSettings;