// File: frontend/src/services/api.ts

import axios from 'axios';
import { UserRole, User } from '../types/user';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8003/api/v1';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

//Request interceptor to add JWT token to requests
api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } 
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  // Add a response interceptor
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle 401 error (e.g., redirect to login)
        console.error('Unauthorized access, redirecting to login');
        // You might want to dispatch a logout action here
      }
      return Promise.reject(error);
    }
  );

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

//Website form submissions
export const registerInterest = async (email: string) => {
  const response = await api.post(`/website/register-interest?email=${encodeURIComponent(email)}`);
  return response.data;
};

export const submitContactForm = async (formData: {
  name: string;
  email: string;
  message: string;
}) => {
  const response = await api.post(`/website/contact`, formData);
  return response.data;
};

// Support ticket submission
export const submitSupportTicket = async (ticketData: {
  issueType: string;
  subject: string;
  description: string;
  urgency: string;
  email: string;
  attachments: File[];
}) => {
  const formData = new FormData();
  formData.append('issue_type', ticketData.issueType);
  formData.append('subject', ticketData.subject);
  formData.append('description', ticketData.description);
  formData.append('urgency', ticketData.urgency);
  formData.append('email', ticketData.email);

  ticketData.attachments.forEach((file) => {
    formData.append('attachments', file);
  });

  const response = await api.post(`/support/ticket`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

//Feedback submission
export const submitFeedback = async (feedbackData: {
  feedbackType: string;
  userExperience: string;
  featureRequest: string;
  improvements: string;
  positiveFeedback: string;
  email: string;
  allowContact: boolean;
}) => {
  const response = await api.post(`/support/feedback`, feedbackData);
  return response.data;
};

//Authentication

export const loginWithGoogle = async (userData: { user_info: any }): Promise<{ access_token: string, user: User }> => {
  const response = await api.post('/auth/google', userData);
  return response.data;
};

export const registerWithGoogle = async (userInfo: any): Promise<{ access_token: string, user: User, action: string }> => {
  console.log('Sending to backend:', userInfo);
  const response = await api.post('/auth/google/register', userInfo);
  return response.data;
};

// Check if the user exists in the backend
export const checkUserExists = async (email: string): Promise<void> => {
  const response = await api.get(`/auth/check-user?email=${email}`);
  return response.data;
};

// Exchange Google OAuth code with optional email in the request body
export const exchangeGoogleCode = async (code: string, email?: string): Promise<{ access_token: string, user: User }> => {
  // Define requestBody with an optional email field
  const requestBody: { code: string; email?: string } = { code };  // `email` is optional

  // Add email to requestBody only if it's available
  if (email) {
    requestBody.email = email;
  }

  const response = await api.post('/auth/google/exchange', requestBody);
  return response.data;
};


export const login = async (email: string, password: string): Promise<{ access_token: string, user: User }> => {
  const response = await api.post('/auth/token', { username: email, password });
  return response.data;
};

export const register = async (email: string, password: string): Promise<{ user: User; access_token: string }> => {
  const response = await api.post('/users/', { email, password });
  return response.data;
};

export const getUsers = async (): Promise<User[]> => {
  const response = await api.get('/admin/users');
  return response.data;
};


export const getUserData = async (token: string): Promise<User> => {
  try {
    console.log('Fetching user data...');
    console.log('Token:', token); // Log the token for debugging
    const response = await axios.get<User>(`${API_URL}/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log('User data received:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    if (axios.isAxiosError(error)) {
      console.error('Response data:', error.response?.data);
      console.error('Response status:', error.response?.status);
    }
    throw error;
  }
};

export const getCurrentUser = async () => {
  const response = await api.get('/users/me');
  return response.data;
};

export const updateUserRole = async (userId: number, newRole: UserRole): Promise<User> => {
  const response = await api.put(`/users/${userId}`, { role: newRole });
  return response.data;
};

export const getSystemConfigs = async (): Promise<Record<string, string>> => {
    const response = await api.get('/admin/system-configs');
    return response.data;
  };
  
  export const updateSystemConfig = async (key: string, value: string): Promise<void> => {
    await api.put('/admin/system-configs', { key, value });
  };
  
  export const getBusinessContexts = async (): Promise<Record<string, string>> => {
    const response = await api.get('/admin/business-contexts');
    return response.data;
  };
  
  export const updateBusinessContext = async (key: string, value: string): Promise<void> => {
    await api.put('/admin/business-contexts', { key, value });
  };
  
  export const getUserContexts = async (): Promise<Record<string, Record<string, string>>> => {
    const response = await api.get('/admin/user-contexts');
    return response.data;
  };
  
  export const updateUserContext = async (userId: string, key: string, value: string): Promise<void> => {
    await api.put(`/admin/user-contexts/${userId}`, { key, value });
  };
  
  
  export const createUser = async (userData: Partial<User>): Promise<User> => {
    const response = await api.post('/admin/users', userData);
    return response.data;
  };
  
  export const updateUser = async (userId: number, userData: Partial<User>): Promise<User> => {
    const response = await api.put(`/admin/users/${userId}`, userData);
    return response.data;
  };
  
  export const deleteUser = async (userId: number): Promise<void> => {
    await api.delete(`/admin/users/${userId}`);
  };

  export const getUserSignature = async (userId: number): Promise<string> => {
    const response = await api.get(`/users/${userId}/signature`);
    return response.data.signature;
  };
  
  export const updateUserSignature = async (userId: number, signatureData: { signature: string }): Promise<void> => {
    await api.put(`/users/${userId}/signature`, signatureData);
  };
  
  export const getUserAnalytics = async (userId: number, timeframe: string) => {
    const response = await api.get(`/analytics/user/${userId}?timeframe=${timeframe}`);
    return response.data;
  };

  export const getOrganizationAnalytics = async (organizationId: number, timeframe: string) => {
    const response = await api.get(`/analytics/organization/${organizationId}?timeframe=${timeframe}`);
    return response.data;
  };

  // Update the existing getTotalAnalytics function to include the timeframe parameter
  export const getTotalAnalytics = async (timeframe: string) => {
    const response = await api.get(`/analytics/total?timeframe=${timeframe}`);
    return response.data;
  };

export const getOrganizations = async () => {
  const response = await api.get('/admin/organizations');
  return response.data;
};

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8003/api/v1';

const getToken = () => {
  return localStorage.getItem('token');
};

export const getEmailsProcessedOverTime = async (
  timeframe: string,
  interval: 'hour' | 'day' | 'week' | 'month',
  id?: string,
  type?: 'user' | 'organization'
) => {
  let url = `${API_BASE_URL}/analytics/emails-processed-over-time?timeframe=${timeframe}&interval=${interval}`;
  if (id && type) {
    url += `&id=${id}&type=${type}`;
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch emails processed over time data');
  }
  return response.json();
};

export const getAllEmailsProcessed = async (
  timeframe: string,
  id?: number,
  type?: 'user' | 'organization'
) => {
  let url = `/analytics/all-emails-processed?timeframe=${timeframe}`;
  if (id && type) {
    url += `&${type}_id=${id}`;
  }
  const response = await api.get(url);
  return response.data;
};

export default api;