import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useGoogleLogin } from '@react-oauth/google';
import { setUser } from '../../store/slices/authSlice';
import { checkUserExists, exchangeGoogleCode } from '../../services/api';
import { FcGoogle } from 'react-icons/fc';

const Register: React.FC = () => {
  const [error, setError] = useState<string>('');  // Error is now a string
  const [email, setEmail] = useState<string | null>(null);  // Store the email
  const [userExists, setUserExists] = useState<boolean | null>(null);  // Track if the user exists (null means not checked yet)
  const [checkingUser, setCheckingUser] = useState<boolean>(true); // Track if we're still checking the user
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Ref to track if the checkUser call has already been made
  const didCallCheckUser = useRef(false);

  // Extract email from query params and check if the user exists (but only once)
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userEmail = searchParams.get('email');
    
    // If no email is provided in the URL, redirect to home page
    if (!userEmail) {
      navigate('/');  // Redirect to homepage
      return;
    }

    // Prevent multiple calls to checkUserExists
    if (!didCallCheckUser.current) {
      setEmail(userEmail);
      setCheckingUser(true);  // Start checking user

      // Call the API to check if the user exists
      checkUserExists(userEmail)
        .then(() => {
          setUserExists(true);  // User exists, allow login
        })
        .catch(() => {
          setUserExists(false);  // User does not exist, show error later
        })
        .finally(() => {
          setCheckingUser(false);  // Finished checking user
        });

      // Mark that the API call was made
      didCallCheckUser.current = true;
    }
  }, [location, navigate]);

  const googleRegister = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/gmail.addons.current.action.compose https://mail.google.com/ https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/gmail.addons.current.message.action https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.compose',
    onSuccess: async (codeResponse) => {
      // Check userExists before proceeding with Google login
      if (!userExists && email) {
        setError('You are not authorised to create an account. Please contact the Support Team to register.');
        return;
      }

      try {
        // Ensure email is not null before sending it to the backend
        if (!email) throw new Error("Email is required");

        // Pass the email to the backend for validation
        const backendResponse = await exchangeGoogleCode(codeResponse.code, email);

        // If emails match, proceed with login
        if (backendResponse.user && backendResponse.access_token) {
          dispatch(setUser({
            user: backendResponse.user,
            token: backendResponse.access_token
          }));
          navigate('/dashboard');  // Redirect to dashboard after successful login
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (err: any) {
        // Check if the error contains a 'detail' field with an array of messages
        if (err.response?.data?.detail && Array.isArray(err.response.data.detail)) {
          const detail = err.response.data.detail;

          // Check if the error is related to missing email or any related validation error
          const isEmailMissing = detail.some((item: any) => item.msg === 'Field required' || item.loc.includes('email'));

          if (isEmailMissing) {
            // Override the error message with the custom one
            setError('You are not authorised to create an account. Please contact the Support Team to register.');
          } else {
            // Otherwise, use the error message from the backend
            const errorMessage = detail[0]?.msg || 'An unknown error occurred';
            setError(errorMessage);
          }
        } else {
          // Otherwise, set a generic error message
          setError(err.message || 'Failed to complete registration process. Please try again.');
        }
      }
    },
    onError: (error) => {
      setError('Google registration failed. Please try again.');
    }
  });

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-600 to-purple-600 py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-2xl"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Create your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Sign up for Inktuitive Email using your Google account
          </p>
        </div>
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{error}</h3>  {/* Display error as a string */}
              </div>
            </div>
          </div>
        )}
        <div>
          <button
            onClick={() => googleRegister()}
            className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-md transition duration-300 ease-in-out"
            disabled={checkingUser}  // Disable button while checking user
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <FcGoogle className="h-5 w-5" />
            </span>
            {checkingUser ? 'Loading...' : 'Sign up with Google'}
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default Register;
