import React from 'react';
import { User } from '../../types/user';
import { PencilIcon } from '@heroicons/react/24/solid';
import Toggle from '../layout/Toggle';

interface UserListProps {
    users: User[];
    onEditUser: (user: User) => void;
    onToggleChange: (userId: number, isActive: boolean) => void;
    onSort: (field: string) => void;
    sortField: string;
    sortOrder: 'asc' | 'desc';
}

const UserList: React.FC<UserListProps> = ({ users, onEditUser, onToggleChange, onSort, sortField, sortOrder }) => {
    if (users.length === 0) {
        return (
            <div className="text-center py-8 text-gray-500">
                No users found.
            </div>
        );
    }

    const getFriendlyName = (value: string | undefined): string => {
        if (!value) return 'N/A';
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    };

    const renderSortIndicator = (field: string) => {
        if (sortField !== field) return null;
        return sortOrder === 'asc' ? '↑' : '↓';
    };

    return (
        <div className="overflow-x-auto shadow-md sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="w-10 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            <span className="sr-only">Actions</span>
                        </th>
                        <th 
                            className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                            onClick={() => onSort('email')}
                        >
                            Email {renderSortIndicator('email')}
                        </th>
                        <th 
                            className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hidden sm:table-cell"
                            onClick={() => onSort('organizationName')}
                        >
                            Organisation {renderSortIndicator('organizationName')}
                        </th>
                        <th 
                            className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hidden sm:table-cell"
                            onClick={() => onSort('role')}
                        >
                            Role {renderSortIndicator('role')}
                        </th>
                        <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Active
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {users.map((user) => (
                        <tr key={user.id} className="hover:bg-gray-100">
                            <td className="w-10 px-2 py-4 whitespace-nowrap text-sm font-medium">
                                <button
                                    className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                                    onClick={() => onEditUser(user)}
                                    aria-label={`Edit ${user.email}`}
                                >
                                    <PencilIcon className="h-4 w-4" />
                                </button>
                            </td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {user.email}
                                <div className="sm:hidden text-xs text-gray-500 mt-1">
                                    {user.organizationName || 'N/A'} - {getFriendlyName(user.role)}
                                </div>
                            </td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                                {user.organizationName || 'N/A'}
                            </td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                                {getFriendlyName(user.role)}
                            </td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                <Toggle
                                    id={`listener-active-${user.id}`}
                                    checked={user.listener_active || false}
                                    onChange={(checked: boolean) => onToggleChange(user.id, checked)}
                                    label=""
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserList;