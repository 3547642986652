// File: frontend/src/store/slices/authSlice.ts

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { User } from '../../types/user';
import { jwtDecode } from 'jwt-decode'; 
import { getUserData } from '../../services/api'; 

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  token: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (_, { getState }) => {
    const state = getState() as { auth: AuthState };
    if (state.auth.token) {
      const userData = await getUserData(state.auth.token);
      return userData;
    }
    throw new Error('No token available');
  }
);

const isTokenExpired = (token: string): boolean => {
  try {
    const decoded: any = jwtDecode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    return false;
  } catch (error) {
    return true;
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: User; token: string }>) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      console.log('Auth Slice - User set:', action.payload.user);
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      console.log('Auth Slice - User logged out');
    },
    restoreSession: (state) => {
      const storedToken = localStorage.getItem('token');
      const storedUser = localStorage.getItem('user');
      if (storedToken && storedUser && !isTokenExpired(storedToken)) {
        state.isAuthenticated = true;
        state.token = storedToken;
        state.user = JSON.parse(storedUser);
        console.log('Auth Slice - Session restored:', state.user);
      } else {
        console.log('Auth Slice - No valid stored session found');
        if (storedToken) {
          console.log('Auth Slice - Token expired');
        }
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    });
  },
});

export const { setUser, logout, restoreSession } = authSlice.actions;
export default authSlice.reducer;
