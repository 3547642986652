import React, { useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Cog8ToothIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const AdminPanel: React.FC = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  const isActive = (path: string) => {
    if (path === '/admin') {
      return location.pathname === '/admin' || location.pathname === '/admin/dashboard';
    }
    return location.pathname.startsWith(path);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const navItems = [
    { name: 'Dashboard', path: '/admin' },
    { name: 'Users', path: '/admin/users' },
    { name: 'Organisations', path: '/admin/organizations' },
    { name: 'Agents', path: '/admin/agents' },
    { name: 'Logs', path: '/admin/logs' },
  ];

  return (
    <div className="bg-gradient-to-br from-indigo-600 to-purple-600 p-4 sm:p-6 h-full flex flex-col">
      <div className="bg-white rounded-xl shadow-2xl p-4 sm:p-8 h-full flex-grow-0 flex-shrink-0 basis-full flex flex-col">
        <div className="flex justify-between items-center mb-6 sm:mb-8">
          <h1 className="text-2xl sm:text-4xl font-extrabold text-gray-800">Admin Panel</h1>
          <div className="flex items-center">
            <NavLink
              to="/admin/settings"
              className={({ isActive }) =>
                `p-2 rounded-full transition-colors duration-200 ${isActive ? 'bg-indigo-100 text-indigo-600' : 'text-gray-600 hover:bg-gray-100'
                }`
              }
              title="Settings"
            >
              <Cog8ToothIcon className="h-6 w-6 sm:h-8 sm:w-8" />
            </NavLink>
            <button
              className="ml-2 p-2 rounded-md text-gray-600 hover:bg-gray-100 sm:hidden"
              onClick={toggleMobileMenu}
            >
              {isMobileMenuOpen ? (
                <XMarkIcon className="h-6 w-6" />
              ) : (
                <Bars3Icon className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        <nav className={`${isMobileMenuOpen ? 'block' : 'hidden'} sm:block mb-6 sm:mb-8`}>
          <ul className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
            {navItems.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.path}
                  className={({ isActive: linkActive }) =>
                    `block px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${isActive(item.path)
                      ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white'
                      : 'text-gray-700 hover:bg-gray-100'
                    }`
                  }
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            type: 'tween',
            ease: 'anticipate',
            duration: 1
          }}
          className="bg-gray-50 rounded-lg p-4 sm:p-6 flex-grow flex-shrink-0 basis-0 overflow-auto"
        >
          <Outlet />
        </motion.div>
      </div>
    </div>
  );
};

export default AdminPanel;
