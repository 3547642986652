import React, { useState, useEffect } from 'react';
import { getAgents, getAgent, updateAgent } from '../../services/agentApi';
import Modal from './AgentModal';
import { PencilIcon } from '@heroicons/react/24/solid';

interface Agent {
  id: number;
  agent_name: string;
  model: string;
  system_prompt: string;
  user_prompt: string;
  temperature: number;
  max_tokens: number;
}

const AgentsManagement: React.FC = () => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    setIsLoading(true);
    try {
      const agentList = await getAgents();
      setAgents(agentList);
      setError(null);
    } catch (err) {
      console.error('Error fetching agents:', err);
      setError('Failed to fetch agents');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (agentName: string) => {
    try {
      const agent = await getAgent(agentName);
      setSelectedAgent(agent);
      setIsModalOpen(true);
    } catch (err) {
      console.error('Error fetching agent details:', err);
      setError('Failed to fetch agent details');
    }
  };

  const handleSubmit = async (agentData: any) => {
    if (selectedAgent) {
      try {
        await updateAgent(selectedAgent.agent_name, agentData);
        setIsModalOpen(false);
        fetchAgents();
      } catch (err) {
        console.error('Error updating agent:', err);
        setError('Failed to update agent');
      }
    }
  };

  if (isLoading) return <div className="text-center py-4">Loading agents...</div>;

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8">
      {error && <div className="text-red-500 mb-4">{error}</div>}

      {agents.length === 0 ? (
        <div className="text-gray-500 text-center py-4">No agents found.</div>
      ) : (
        <div className="overflow-x-auto shadow-md sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="w-10 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="sr-only">Actions</span>
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Agent Name
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">
                  Model
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {agents.map((agent) => (
                <tr key={agent.id} className="hover:bg-gray-100">
                  <td className="w-10 px-2 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => handleEdit(agent.agent_name)}
                      className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                      aria-label={`Edit ${agent.agent_name}`}
                    >
                      <PencilIcon className="h-4 w-4" />
                    </button>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {agent.agent_name}
                    <div className="sm:hidden text-xs text-gray-500 mt-1">
                      Model: {agent.model}
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                    {agent.model}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isModalOpen && (
        <Modal
          agent={selectedAgent}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default AgentsManagement;