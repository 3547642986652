// File: frontend/src/services/businessSettingsApi.ts

import api from './api';
import { User } from '../types/user';
import { Organization, BusinessContextSettings } from '../types/settings';
import axios, { AxiosResponse } from 'axios';

class BusinessSettingsApi {
    // Organization routes
    async getOrganizations(): Promise<Organization[]> {
        const response = await api.get('/admin/organizations');
        return response.data;
    }

    async createOrganization(orgData: Partial<Organization>): Promise<Organization> {
        const response = await api.post('/admin/organizations', orgData);
        return response.data;
    }

    async createOrganizationWithOwner(orgData: Partial<Organization>, ownerId: number): Promise<Organization> {
        const response = await api.post('/admin/organizations/with-owner', { ...orgData, owner_id: ownerId });
        return response.data;
    }

    async updateOrganization(orgId: number, orgData: Partial<Organization>): Promise<Organization> {
        const response = await api.put(`/admin/organizations/${orgId}`, orgData);
        return response.data;
    }

    async deleteOrganization(orgId: number): Promise<void> {
        await api.delete(`/admin/organizations/${orgId}`);
    }

    // User-Organization routes

    async getUsers(): Promise<User[]> {
        const response = await api.get('/admin/users');
        return response.data;
    }

    async getOrganizationByUserId(userId: number): Promise<Organization | null> {
        try {
            const response = await api.get(`/admin/organizations/users/${userId}/organization`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {  // Check if error is an AxiosError
                if (error.response && error.response.status === 404) {
                    // User is not assigned to an organization
                    return null;
                }
            }
            throw error;  // Re-throw the error if it is not handled
        }
    }

    async addUserToOrganization(orgId: number, userId: number): Promise<AxiosResponse<void>> {
        return await api.post(`/admin/organizations/${orgId}/users/${userId}`);
    }

    async addUserToOrganizationByEmail(orgId: number, userEmail: string): Promise<void> {
        await api.post(`/admin/organizations/${orgId}/users`, { email: userEmail });
    }

    async removeUserFromOrganization(orgId: number, userId: number): Promise<void> {
        await api.delete(`/admin/organizations/${orgId}/users/${userId}`);
    }

    async getUsersForOrganization(orgId: number): Promise<User[]> {
        const response = await api.get(`/admin/organizations/${orgId}/users`);
        return response.data;
    }

    // Business Context routes
    async getOrganizationBusinessContext(orgId: number): Promise<BusinessContextSettings> {
        const response = await api.get(`/admin/business-context/organizations/${orgId}/business-context`);
        return response.data;
    }

    async createOrganizationBusinessContext(
        orgId: number,
        settings: Partial<BusinessContextSettings>
    ): Promise<BusinessContextSettings> {
        const response = await api.post(`/admin/business-context/organizations/${orgId}/business-context`, settings);
        return response.data;
    }

    async updateOrganizationBusinessContext(
        orgId: number,
        settings: Partial<BusinessContextSettings>
    ): Promise<BusinessContextSettings> {
        const response = await api.put(`/admin/business-context/organizations/${orgId}/business-context`, settings);
        return response.data;
    }

    // Copy settings route
    async copyOrganizationSettingsToUser(userId: number, orgId: number): Promise<void> {
        await api.post(`/admin/business-context/users/${userId}/copy-org-settings`, { org_id: orgId });
    }
}

const businessSettingsApi = new BusinessSettingsApi();
export default businessSettingsApi;
