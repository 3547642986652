// src/components/widgets/FeedbackForm.tsx

import React, { useState } from 'react';
import { useToast } from '../../context/ToastContext'; 
import { submitFeedback } from '../../services/api';

const FeedbackForm: React.FC = () => {
  const [formData, setFormData] = useState({
    feedbackType: '',
    userExperience: '',
    featureRequest: '',
    improvements: '',
    positiveFeedback: '',
    email: '',
    allowContact: false
  });

  const [errors, setErrors] = useState<{[key: string]: string}>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showToast } = useToast();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const validateForm = () => {
    const newErrors: {[key: string]: string} = {};
    if (!formData.feedbackType) newErrors.feedbackType = 'Feedback type is required';
    if (!formData.userExperience.trim()) newErrors.userExperience = 'User experience description is required';
    if (!formData.featureRequest.trim()) newErrors.featureRequest = 'Feature request or suggestion is required';
    if (!formData.improvements.trim()) newErrors.improvements = 'Improvement suggestions are required';
    if (!formData.positiveFeedback.trim()) newErrors.positiveFeedback = 'Positive feedback is required';
    if (formData.allowContact && !formData.email.trim()) newErrors.email = 'Email is required when allowing contact';
    if (!formData.allowContact) newErrors.allowContact = 'Please allow us to contact you for further information';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      showToast('Please fill in all required fields.', 'error');
      return;
    }

    setIsSubmitting(true);

    try {
      await submitFeedback(formData);
      showToast('Your feedback has been submitted successfully!', 'success');
      // Reset form
      setFormData({
        feedbackType: '',
        userExperience: '',
        featureRequest: '',
        improvements: '',
        positiveFeedback: '',
        email: '',
        allowContact: false
      });
    } catch (error) {
      console.error('Error submitting feedback:', error);
      showToast('An error occurred. Please try again.', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Provide Feedback</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="feedbackType" className="block text-sm font-medium text-gray-700 mb-1">
            Feedback Type *
          </label>
          <select
            id="feedbackType"
            name="feedbackType"
            value={formData.feedbackType}
            onChange={handleInputChange}
            className={`w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${errors.feedbackType ? 'border-red-500' : 'border-gray-300'}`}
            required
          >
            <option value="">Select feedback type</option>
            <option value="General Feedback">General Feedback</option>
            <option value="Feature Suggestion">Feature Suggestion</option>
            <option value="User Experience">User Experience</option>
            <option value="Bug Report">Bug Report</option>
            <option value="Other">Other</option>
          </select>
          {errors.feedbackType && <p className="mt-1 text-sm text-red-500">{errors.feedbackType}</p>}
        </div>
        <div>
          <label htmlFor="userExperience" className="block text-sm font-medium text-gray-700 mb-1">
            Describe your user experience *
          </label>
          <textarea
            id="userExperience"
            name="userExperience"
            value={formData.userExperience}
            onChange={handleInputChange}
            rows={3}
            className={`w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${errors.userExperience ? 'border-red-500' : 'border-gray-300'}`}
            required
          ></textarea>
          {errors.userExperience && <p className="mt-1 text-sm text-red-500">{errors.userExperience}</p>}
        </div>
        <div>
          <label htmlFor="featureRequest" className="block text-sm font-medium text-gray-700 mb-1">
            Feature request or suggestion *
          </label>
          <textarea
            id="featureRequest"
            name="featureRequest"
            value={formData.featureRequest}
            onChange={handleInputChange}
            rows={3}
            className={`w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${errors.featureRequest ? 'border-red-500' : 'border-gray-300'}`}
            required
          ></textarea>
          {errors.featureRequest && <p className="mt-1 text-sm text-red-500">{errors.featureRequest}</p>}
        </div>
        <div>
          <label htmlFor="improvements" className="block text-sm font-medium text-gray-700 mb-1">
            What could we improve? *
          </label>
          <textarea
            id="improvements"
            name="improvements"
            value={formData.improvements}
            onChange={handleInputChange}
            rows={3}
            className={`w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${errors.improvements ? 'border-red-500' : 'border-gray-300'}`}
            required
          ></textarea>
          {errors.improvements && <p className="mt-1 text-sm text-red-500">{errors.improvements}</p>}
        </div>
        <div>
          <label htmlFor="positiveFeedback" className="block text-sm font-medium text-gray-700 mb-1">
            What did you like about our product/service? *
          </label>
          <textarea
            id="positiveFeedback"
            name="positiveFeedback"
            value={formData.positiveFeedback}
            onChange={handleInputChange}
            rows={3}
            className={`w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${errors.positiveFeedback ? 'border-red-500' : 'border-gray-300'}`}
            required
          ></textarea>
          {errors.positiveFeedback && <p className="mt-1 text-sm text-red-500">{errors.positiveFeedback}</p>}
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
            Your Email {formData.allowContact ? '*' : '(optional)'}
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className={`w-full px-3 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
            required={formData.allowContact}
          />
          {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="allowContact"
            name="allowContact"
            checked={formData.allowContact}
            onChange={handleInputChange}
            className={`h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded ${errors.allowContact ? 'border-red-500' : ''}`}
            required
          />
          <label htmlFor="allowContact" className="ml-2 block text-sm text-gray-900">
            May we contact you for further information? *
          </label>
        </div>
        {errors.allowContact && <p className="mt-1 text-sm text-red-500">{errors.allowContact}</p>}
        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
        </button>
      </form>
    </div>
  );
};

export default FeedbackForm;