import React from 'react';
import { NavLink } from 'react-router-dom';

import InktuitiveLogo from '../../images/logos/Inktuitive Logo.png';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const footerLinks = [
    { to: "/", label: "Home" },
    { to: "/features", label: "Features" },
    { to: "/about", label: "About" },
    { to: "/contact", label: "Contact" },
    { to: "/privacy", label: "Privacy Policy" },
    { to: "/terms", label: "Terms of Service" },
  ];

  return (
    <footer className="bg-gray-900 text-white py-8 mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            <img src={InktuitiveLogo} alt="Inktuitive Logo" className="h-10 w-10 mr-3" />
            <NavLink to="/" className="text-xl font-semibold hover:text-indigo-400 transition duration-300">
              Inktuitive Email
            </NavLink>
          </div>
          <nav className="flex flex-wrap justify-center md:justify-end gap-4 mb-4 md:mb-0">
            {footerLinks.map((link) => (
              <NavLink
                key={link.to}
                to={link.to}
                className="text-gray-400 hover:text-white transition duration-300"
              >
                {link.label}
              </NavLink>
            ))}
          </nav>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-400 text-sm mb-4 md:mb-0">
            &copy; {currentYear} Inktuitive Email Inc. All rights reserved.
          </p>
          <div className="flex space-x-4">{/*}
            <a href="https://twitter.com/inktuitiveemail" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-300">
              Twitter
            </a>
            <a href="https://www.linkedin.com/company/inktuitiveemail" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-300">
              LinkedIn
            </a>
            <a href="https://github.com/inktuitiveemail" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-300">
              GitHub
            </a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;