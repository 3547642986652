import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface TimeSeriesChartProps {
  data: any[];
  interval: 'day' | 'week' | 'month';
}

const TimeSeriesChart: React.FC<TimeSeriesChartProps> = ({ data, interval }) => {
  const formatXAxis = (tickItem: string) => {
    const date = new Date(tickItem);
    switch (interval) {
      case 'day':
        return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
      case 'week':
        const [year, week] = tickItem.split('-W');
        return `Week ${week}, ${year}`;
      case 'month':
        return date.toLocaleDateString([], { month: 'short', year: 'numeric' });
      default:
        return tickItem;
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="timestamp" tickFormatter={formatXAxis} />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip
          labelFormatter={(value: string) => formatXAxis(value)}
          formatter={(value: number, name: string) => {
            if (name === 'total_cost') {
              return [`$${value.toFixed(2)}`, name];
            }
            return [value, name];
          }}
        />
        <Legend />
        <Line yAxisId="left" type="monotone" dataKey="emails_processed" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line yAxisId="left" type="monotone" dataKey="emails_failed" stroke="#82ca9d" activeDot={{ r: 8 }} />
        <Line yAxisId="left" type="monotone" dataKey="drafts_written" stroke="#ffc658" activeDot={{ r: 8 }} />
        <Line yAxisId="right" type="monotone" dataKey="total_cost" stroke="#ff7300" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TimeSeriesChart;