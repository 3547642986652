import React, { useState, useEffect, useRef } from 'react';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  TrashIcon,
  PlusIcon,
  XMarkIcon,
  CheckIcon,
  Bars3Icon,
  SparklesIcon,
} from '@heroicons/react/24/solid';
import { Category, EmailSortingRule, RuleType, emailSettingsApi } from '../../services/emailSettingsApi';
import { Draggable } from 'react-beautiful-dnd';
import { generateRulesForCategory, getTaskStatus } from '../../services/agentApi';
import { useToast } from '../../context/ToastContext';

interface CategoryItemProps {
  category: Category;
  index: number;
  onRemoveCategory: (categoryName: string) => void;
  onToggleResponseRequired: (index: number) => void;
  onAddRule: (categoryName: string, rule: Partial<EmailSortingRule>) => void;
  onRemoveRule: (categoryName: string, ruleId: number) => void;
  labelStatus: 'pending' | 'found' | 'not_found';
  isDeleting: boolean;
  expandedCategory: string | null;
  setExpandedCategory: React.Dispatch<React.SetStateAction<string | null>>;
  onUpdateCategory?: (updatedCategory: Category) => void;
  userId: number;
  isAdmin: boolean;
}

const CategoryItem: React.FC<CategoryItemProps> = ({
  category,
  index,
  onRemoveCategory,
  onToggleResponseRequired,
  onAddRule,
  onRemoveRule,
  labelStatus,
  isDeleting,
  expandedCategory,
  setExpandedCategory,
  onUpdateCategory,
  userId,
  isAdmin,
}) => {
  const [newRuleType, setNewRuleType] = useState<RuleType>(RuleType.INCLUDE_DOMAINS);
  const [newRuleValue, setNewRuleValue] = useState('');
  const [ruleToDelete, setRuleToDelete] = useState<number | null>(null);
  const [isGeneratingRules, setIsGeneratingRules] = useState(false);
  const [generationStatus, setGenerationStatus] = useState<string | null>(null);
  const [taskId, setTaskId] = useState<string | null>(null);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const { showToast } = useToast();

  const isExpanded = expandedCategory === category.name;
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    setExpandedCategory(isExpanded ? null : category.name);
  };

  const getCategoryColor = () => {
    if (category.is_app_created) return 'bg-purple-100';
    if (labelStatus === 'not_found') return 'bg-orange-100';
    return 'bg-green-100';
  };

  const categoryColor = getCategoryColor();

  const handleAddRule = () => {
    onAddRule(category.name, {
      rule_type: newRuleType,
      rule_data: { value: newRuleValue },
      description: `${newRuleType}: ${newRuleValue}`
    });
    setNewRuleValue('');
  };

  const confirmDeleteRule = (ruleId: number) => {
    if (ruleToDelete === ruleId) {
      onRemoveRule(category.name, ruleId);
      setRuleToDelete(null);
    } else {
      setRuleToDelete(ruleId);
    }
  };

  const cancelDeleteRule = () => {
    setRuleToDelete(null);
  };

  const getRuleValue = (rule: EmailSortingRule): string => {
    if (rule.rule_data && typeof rule.rule_data === 'object') {
      if ('value' in rule.rule_data) {
        return String(rule.rule_data.value);
      }
      return String(Object.values(rule.rule_data)[0]);
    }
    return 'N/A';
  };

  const handleGenerateRules = async () => {
    try {
      setIsGeneratingRules(true);
      setGenerationStatus('Generating Rules...');
      let response;
      if (isAdmin) {
        response = await generateRulesForCategory(category.name, userId);
      } else {
        response = await emailSettingsApi.generateRulesForCategory(category.name);
      }
      setTaskId(response.task_id);
    } catch (error: any) {
      console.error('Error generating rules:', error);
      showToast(error.detail || 'An error occurred while generating rules', 'error');
      setIsGeneratingRules(false);
      setGenerationStatus(null);
    }
  };

  useEffect(() => {
    const checkTaskStatus = async () => {
      if (taskId) {
        try {
          const taskStatus = await getTaskStatus(taskId);
          console.log('Task status:', taskStatus);
          switch (taskStatus.status) {
            case 'PENDING':
            case 'STARTED':
            case 'Processing':
              setGenerationStatus('Generating Rules...');
              break;
            case 'SUCCESS':
            case 'Completed':
              setGenerationStatus('Rules Generated');
              setIsGeneratingRules(false);
              if (taskStatus.data && taskStatus.data.category && onUpdateCategory) {
                onUpdateCategory({
                  ...category,
                  rules: taskStatus.data.category.rules
                });
              }
              showToast('Rules generated successfully', 'success');
              clearInterval(intervalIdRef.current!);
              setTaskId(null);
              break;
            case 'FAILURE':
            case 'Failed':
              setGenerationStatus('Error, Try again later');
              setIsGeneratingRules(false);
              showToast(taskStatus.detail || 'Failed to generate rules', 'error');
              clearInterval(intervalIdRef.current!);
              setTaskId(null);
              break;
            default:
              setGenerationStatus(null);
              setIsGeneratingRules(false);
              clearInterval(intervalIdRef.current!);
              setTaskId(null);
          }
        } catch (error) {
          console.error('Error checking task status:', error);
          setGenerationStatus('Error, Try again later');
          setIsGeneratingRules(false);
          clearInterval(intervalIdRef.current!);
          setTaskId(null);
        }
      }
    };

    if (taskId) {
      // Check immediately and then every 2 seconds
      checkTaskStatus();
      intervalIdRef.current = setInterval(checkTaskStatus, 2000);
    }

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [taskId, showToast, category, onUpdateCategory]);

  return (
    <Draggable draggableId={category.name} index={index}>
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`border rounded-lg overflow-hidden ${categoryColor}`}
        >
          <div className="p-4">
            <div className="flex items-center justify-between" {...provided.dragHandleProps}>
              <div className="flex items-center">
                <div className="mr-2 cursor-move">
                  <Bars3Icon className="h-5 w-5 text-gray-400" />
                </div>
                <span className="font-semibold">{category.name}</span>
                {labelStatus === 'not_found' && (
                  <span className="ml-2 text-xs text-orange-600">Unable to find label in Gmail</span>
                )}
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => onToggleResponseRequired(index)}
                  className={`btn btn-xs ${category.response_required ? 'btn-primary' : 'btn-ghost'}`}
                  title={category.response_required ? 'Keep in Inbox' : 'Move out of Inbox'}
                >
                  {category.response_required ? 'Keep in Inbox' : 'Move out of Inbox'}
                </button>
                <button
                  onClick={() => onRemoveCategory(category.name)}
                  className="btn btn-xs btn-ghost text-red-500"
                  disabled={isDeleting}
                >
                  {isDeleting ? <span className="loading loading-spinner loading-xs"></span> : <TrashIcon className="h-4 w-4" />}
                </button>
                <button onClick={toggleExpand} className="btn btn-xs btn-ghost">
                  {isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
                </button>
              </div>
            </div>
          </div>
          <div
            ref={contentRef}
            className="overflow-hidden transition-all duration-300 ease-in-out"
            style={{
              maxHeight: isExpanded ? `${contentRef.current?.scrollHeight}px` : '0px',
              opacity: isExpanded ? 1 : 0,
            }}
          >
            <div className="p-4">
              <div className="flex justify-between items-center mb-2">
                <h4 className="font-semibold text-sm">Rules</h4>
                <button
                  onClick={handleGenerateRules}
                  className={`btn btn-xs ${isGeneratingRules ? 'btn-disabled' : 'btn-ghost'}`}
                  disabled={isGeneratingRules}
                >
                  {isGeneratingRules ? (
                    <>
                      <span className="loading loading-spinner loading-xs mr-2"></span>
                      {generationStatus}
                    </>
                  ) : (
                    <>
                      <SparklesIcon className="h-3 w-3 mr-1" />
                      Generate Rules
                    </>
                  )}
                </button>
              </div>
              {category.rules && category.rules.length > 0 ? (
                <ul className="space-y-2 mb-4">
                  {category.rules.map((rule) => (
                    <li key={rule.id} className="flex items-start justify-between text-xs">
                      <span className="select-text flex-grow mr-2">{`${rule.rule_type}: ${getRuleValue(rule)}`}</span>
                      <div className="flex-shrink-0">
                        {ruleToDelete === rule.id ? (
                          <div className="flex">
                            <button
                              onClick={() => rule.id !== undefined && confirmDeleteRule(rule.id)}
                              className="btn btn-xs btn-ghost text-green-500 mr-1"
                            >
                              <CheckIcon className="h-3 w-3" />
                            </button>
                            <button
                              onClick={cancelDeleteRule}
                              className="btn btn-xs btn-ghost text-red-500"
                            >
                              <XMarkIcon className="h-3 w-3" />
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={() => rule.id !== undefined && confirmDeleteRule(rule.id)}
                            className="btn btn-xs btn-ghost text-red-500"
                            disabled={rule.id === undefined}
                          >
                            <TrashIcon className="h-3 w-3" />
                          </button>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="mb-4 select-text text-xs">No rules for this category.</p>
              )}
              <div className="mt-4">
                <h5 className="font-semibold mb-2 text-sm">Add New Rule</h5>
                <div className="flex flex-col sm:flex-row items-start sm:items-end space-y-2 sm:space-y-0 sm:space-x-2">
                  <div className="w-full sm:w-1/3">
                    <label htmlFor="ruleType" className="block text-xs font-medium text-gray-700 mb-1">
                      Rule Type
                    </label>
                    <select
                      id="ruleType"
                      className="select select-bordered select-xs w-full"
                      value={newRuleType}
                      onChange={(e) => setNewRuleType(e.target.value as RuleType)}
                    >
                      {Object.values(RuleType).map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full sm:w-1/2">
                    <label htmlFor="ruleValue" className="block text-xs font-medium text-gray-700 mb-1">
                      Rule Value
                    </label>
                    <input
                      id="ruleValue"
                      type="text"
                      placeholder="Enter rule value"
                      className="input input-bordered input-xs w-full"
                      value={newRuleValue}
                      onChange={(e) => setNewRuleValue(e.target.value)}
                    />
                  </div>
                  <button
                    onClick={handleAddRule}
                    className="btn btn-primary btn-xs w-full sm:w-auto"
                    disabled={!newRuleValue.trim()}
                  >
                    <PlusIcon className="h-3 w-3 mr-1" /> Add Rule
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      )}
    </Draggable>
  );
};

export default CategoryItem;
