// File: frontend/src/components/AdminRoute.tsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { UserRole } from '../types/user';

interface AdminRouteProps {
  element: React.ReactElement;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ element }) => {
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);

  console.log('AdminRoute - isAuthenticated:', isAuthenticated);
  console.log('AdminRoute - user:', user);

  if (!isAuthenticated) {
    console.log('AdminRoute - Redirecting to login');
    return <Navigate to="/login" />;
  }

  if (user?.role !== UserRole.ADMIN) {
    console.log('AdminRoute - Redirecting to dashboard (not admin)');
    return <Navigate to="/dashboard" />;
  }

  console.log('AdminRoute - Rendering admin content');
  return element;
};

export default AdminRoute;