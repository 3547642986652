import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { getUserAnalytics } from '../../services/api';
import { FiMail, FiEdit, FiClock, FiTrendingUp, FiUser, FiFilter, FiEdit3, FiSettings } from 'react-icons/fi';

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const [userAnalytics, setUserAnalytics] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const calculateTimeSaved = (emailsProcessed: number, draftsWritten: number) => {
    const emailTime = emailsProcessed * 10; // 10 seconds per email
    const draftTime = draftsWritten * 5 * 60; // 5 minutes per draft, converted to seconds
    const totalSeconds = emailTime + draftTime;
    return Math.round(totalSeconds / 60); // Convert to minutes and round
  };

  useEffect(() => {
    const fetchUserAnalytics = async () => {
      if (user && user.id) {
        try {
          const data = await getUserAnalytics(user.id, 'last_30_days'); // or use a state variable for timeframe
          // Calculate time saved
          const timeSaved = calculateTimeSaved(data.emails_processed, data.drafts_written);
          setUserAnalytics({ ...data, time_saved: timeSaved });
        } catch (error) {
          console.error('Error fetching user analytics:', error);
          setError('Failed to load analytics data. Please try again later.');
        }
      }
    };

    fetchUserAnalytics();
  }, [user]);

  return (
    <div className="bg-gradient-to-br from-indigo-600 to-purple-600 p-4 sm:p-6 h-screen flex flex-col">
      <div className="bg-white rounded-xl shadow-2xl p-4 sm:p-8 h-full flex-grow-0 flex-shrink-0 basis-full flex flex-col">
        <h1 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-6">Welcome to Your Dashboard</h1>
        
        <div className="bg-gray-50 rounded-lg p-4 sm:p-6 flex-grow overflow-auto">
          <div className="space-y-8">
            <div className="bg-indigo-50 rounded-lg p-6 flex items-center">
              <div className="bg-indigo-100 rounded-full p-3 mr-4">
                <FiUser className="text-indigo-600 text-2xl" />
              </div>
              <div>
                <h2 className="text-2xl font-bold text-indigo-800 mb-2">Hello, <span className="break-words">{user?.email}</span></h2>
                <p className="text-lg text-gray-700">Role: <span className="font-semibold">{user?.role}</span></p>
              </div>
            </div>

            {error && (
              <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                <p className="font-bold">Error</p>
                <p>{error}</p>
              </div>
            )}

            {userAnalytics && (
              <div>
                <h2 className="text-2xl font-bold mb-4 text-gray-800">Your Analytics</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                  <AnalyticCard icon={<FiMail />} title="Emails Processed" value={userAnalytics.emails_processed} />
                  <AnalyticCard icon={<FiEdit />} title="Drafts Written" value={userAnalytics.drafts_written} />
                  <AnalyticCard icon={<FiClock />} title="Time Saved" value={`${userAnalytics.time_saved} mins`} />
                  <AnalyticCard icon={<FiTrendingUp />} title="Efficiency Gain" value={`${((userAnalytics.time_saved / 60) / 8).toFixed(2)} days`} />
                </div>
              </div>
            )}

            <div>
              <h2 className="text-2xl font-bold mb-4 text-gray-800">Quick Actions</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                <ActionCard to="/settings/user-context" icon={<FiUser />} title="User Context" description="Update your information" />
                <ActionCard to="/settings/email-sorting" icon={<FiFilter />} title="Email Sorting" description="Manage categories and rules" />
                <ActionCard to="/settings/signature" icon={<FiEdit3 />} title="Signature" description="Customize your signature" />
                {user?.role === 'admin' && (
                  <ActionCard to="/admin" icon={<FiSettings />} title="Admin Panel" description="Access admin functions" />
                )}
              </div>
            </div>

            <div className="bg-white rounded-lg p-6 shadow">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Getting Started</h2>
              <ul className="space-y-2 text-gray-700">
                <li className="flex items-center"><div className="bg-indigo-100 rounded-full p-1 mr-2"><FiUser className="text-indigo-600" /></div>Update your user context to personalize your experience</li>
                <li className="flex items-center"><div className="bg-indigo-100 rounded-full p-1 mr-2"><FiFilter className="text-indigo-600" /></div>Set up email sorting categories and category rules</li>
                <li className="flex items-center"><div className="bg-indigo-100 rounded-full p-1 mr-2"><FiEdit3 className="text-indigo-600" /></div>Customize your email signature for a professional touch</li>
                <li className="flex items-center"><div className="bg-indigo-100 rounded-full p-1 mr-2"><FiSettings className="text-indigo-600" /></div>Explore the features available in the settings</li>
                <li className="flex items-center"><div className="bg-indigo-100 rounded-full p-1 mr-2"><FiMail className="text-indigo-600" /></div>Contact support to configure your AI auto-response settings</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AnalyticCard: React.FC<{ icon: React.ReactNode, title: string, value: string | number }> = ({ icon, title, value }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex items-center">
    <div className="bg-indigo-100 rounded-full p-3 mr-4">
      {icon}
    </div>
    <div>
      <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      <p className="text-2xl font-bold text-indigo-600">{value}</p>
    </div>
  </div>
);

const ActionCard: React.FC<{ to: string, icon: React.ReactNode, title: string, description: string }> = ({ to, icon, title, description }) => (
  <Link to={to} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition duration-300 flex items-center">
    <div className="bg-indigo-100 rounded-full p-3 mr-4">
      {icon}
    </div>
    <div>
      <h3 className="text-lg font-semibold text-indigo-600">{title}</h3>
      <p className="text-sm text-gray-600">{description}</p>
    </div>
  </Link>
);

export default Dashboard;