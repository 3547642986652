import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { getLogs } from '../../services/adminApi';
import { Log, LogPagination } from '../../types/log';
import debounce from 'lodash/debounce';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

const MAX_LOGS = 5000;       // Maximum number of logs to fetch
const BATCH_SIZE = 2000;     // Number of logs per API call
const DEBOUNCE_DELAY = 300; // Debounce delay for search

const LogsView: React.FC = () => {
  // State to store all fetched logs
  const [allLogs, setAllLogs] = useState<Log[]>([]);

  // State to store logs after applying search filters
  const [filteredLogs, setFilteredLogs] = useState<Log[]>([]);

  // Existing state variables
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [levelFilter, setLevelFilter] = useState('');
  const [serviceFilter, setServiceFilter] = useState('');
  const [entryTypeFilter, setEntryTypeFilter] = useState('');

  // Pagination state variables
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 20;
  const [totalLogs, setTotalLogs] = useState<number>(0);
  const totalPages = Math.ceil(totalLogs / pageSize);

  // Effect to manage body class
  useEffect(() => {
    document.body.classList.add('scrollable-page');
    return () => {
      document.body.classList.remove('scrollable-page');
    };
  }, []);

  // Fetch logs in batches up to MAX_LOGS
  const fetchAllLogs = useCallback(async () => {
    try {
      setLoading(true);
      let fetchedLogs: Log[] = [];
      let page = 1;

      while (fetchedLogs.length < MAX_LOGS) {
        const response: LogPagination = await getLogs({
          page: page,
          page_size: BATCH_SIZE,
          level: levelFilter,
          service: serviceFilter,
          entry_type: entryTypeFilter,
        });

        if (response.logs.length === 0) {
          // No more logs to fetch
          break;
        }

        fetchedLogs = [...fetchedLogs, ...response.logs];

        if (fetchedLogs.length >= response.total) {
          // Fetched all available logs
          break;
        }

        page += 1;
      }

      // Limit to MAX_LOGS
      if (fetchedLogs.length > MAX_LOGS) {
        fetchedLogs = fetchedLogs.slice(0, MAX_LOGS);
      }

      setAllLogs(fetchedLogs);
      setFilteredLogs(fetchedLogs);
      setTotalLogs(fetchedLogs.length);
      setError(null);
    } catch (err) {
      setError('Failed to fetch logs');
      console.error('Error fetching logs:', err);
    } finally {
      setLoading(false);
    }
  }, [levelFilter, serviceFilter, entryTypeFilter]);

  // Function to filter logs based on search terms
  const filterLogs = useCallback(() => {
    if (!searchTerm.trim()) {
      setFilteredLogs(allLogs);
      setTotalLogs(allLogs.length);
      setCurrentPage(1);
      return;
    }

    // Split search term into individual terms (separated by spaces)
    const terms = searchTerm.trim().toLowerCase().split(/\s+/);

    const filtered = allLogs.filter((log) =>
      terms.every((term) =>
        log.message.toLowerCase().includes(term) ||
        (log.user_email && log.user_email.toLowerCase().includes(term)) ||
        (log.service && log.service.toLowerCase().includes(term))
      )
    );

    setFilteredLogs(filtered);
    setTotalLogs(filtered.length);
    setCurrentPage(1);
  }, [searchTerm, allLogs]);

  // Debounce the filterLogs function to optimize performance
  const debouncedFilterLogs = useMemo(() => debounce(filterLogs, DEBOUNCE_DELAY), [filterLogs]);

  // Fetch all logs on component mount or when filters change
  useEffect(() => {
    fetchAllLogs();
  }, [fetchAllLogs]);

  // Apply filtering whenever searchTerm changes
  useEffect(() => {
    debouncedFilterLogs();
    return () => {
      debouncedFilterLogs.cancel();
    };
  }, [debouncedFilterLogs, searchTerm]);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Handle refresh
  const handleRefresh = () => {
    fetchAllLogs();
  };

  // Function to determine badge classes based on log level
  const getLevelBadgeClass = (level: string) => {
    switch (level) {
      case 'INFO':
        return 'bg-blue-100 text-blue-800';
      case 'WARNING':
        return 'bg-yellow-100 text-yellow-800';
      case 'ERROR':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Determine the logs to display on the current page
  const displayedLogs = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return filteredLogs.slice(startIndex, startIndex + pageSize);
  }, [filteredLogs, currentPage, pageSize]);

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8 py-4 sm:py-6">
      {/* Search and Filters */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mb-4 sm:mb-6">
        {/* Search Input */}
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        {/* Existing Filters */}
        <select
          value={levelFilter}
          onChange={(e) => setLevelFilter(e.target.value)}
          className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <option value="">All Levels</option>
          <option value="INFO">INFO</option>
          <option value="DEBUG">DEBUG</option>
          <option value="WARNING">WARNING</option>
          <option value="ERROR">ERROR</option>
        </select>
        <select
          value={serviceFilter}
          onChange={(e) => setServiceFilter(e.target.value)}
          className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <option value="">All Services</option>
          <option value="api">API</option>
          <option value="check_new_emails_task">Check New Emails</option>
          <option value="check_unfinished_emails_task">Check Unfinished Emails</option>
          <option value="gmail_listener">Process Emails</option>
          <option value="memory_tracker">Memory Tracker</option>
          <option value="Admin_action">Admin Action</option>
          <option value="Email_sent">Email Sent</option>
        </select>
        <select
          value={entryTypeFilter}
          onChange={(e) => setEntryTypeFilter(e.target.value)}
          className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <option value="">All Entry Types</option>
          <option value="toggle_gmail_listener">App Activated/Deactivated</option>
          <option value="frontend_request">Frontend Request</option>
          <option value="background_task">Background Task</option>
          <option value="create_user">Create User</option>
          <option value="update_user">Update User</option>
          <option value="delete_user">Delete User</option>
          <option value="PROCESSING_SUMMARY">Process Email Summary</option>
          <option value="process_emails">Process Emails</option>
          <option value="check_new_emails">Check New Emails</option>
        </select>
        {/* Refresh Button */}
        <button
          onClick={handleRefresh}
          className="w-full sm:w-auto px-4 py-2 bg-indigo-600 text-white text-sm rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <ArrowPathIcon className="h-5 w-5 mr-2 inline" />
          Refresh
        </button>
      </div>

      {/* Logs Table and Pagination */}
      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
        </div>
      ) : error ? (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      ) : filteredLogs.length === 0 ? (
        <div className="text-center py-8">
          <span className="text-gray-500">No logs found.</span>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto shadow-md sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Timestamp</th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Level</th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Service</th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Message</th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">Entry Type</th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">User</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {displayedLogs.map((log) => (
                  <tr key={log.id} className="hover:bg-gray-50">
                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(log.timestamp).toLocaleString()}</td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getLevelBadgeClass(log.level)}`}>
                        {log.level}
                      </span>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">{log.service}</td>
                    <td className="px-3 py-4 text-sm text-gray-500">{log.message}</td>
                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">{log.entry_type}</td>
                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                      {log.user_email ? `${log.user_email} (${log.user_type})` : 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className={`px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-500">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              className={`px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${
                currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default LogsView;
