// File: frontend/src/services/userContextApi.ts

import api from './api';

export interface UserContextSettings {
  id: number;
  user_id: number;
  name: string;
  role: string;
  personal_context: string;
}

export const userContextApi = {
  getUserContextSettings: async (userId: number): Promise<UserContextSettings> => {
    const response = await api.get(`/user_settings/${userId}/context`);
    return response.data;
  },

  updateUserContextSettings: async (userId: number, settings: Partial<UserContextSettings>): Promise<UserContextSettings> => {
    const response = await api.put(`/user_settings/${userId}/context`, settings);
    return response.data;
  },
};

export default userContextApi;