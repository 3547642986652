// File: frontend/src/components/admin/AccountInfo.tsx

import React, { useState } from 'react';
import { User, UserRole, AuthMethod, SubscriptionPlan } from '../../types/user';
import Toggle from '../layout/Toggle';
import { TrashIcon } from '@heroicons/react/24/outline';
import ConfirmationModal from '../settings/ConfirmationModal';
import { useToast } from '../../context/ToastContext'; // Import the useToast hook

interface AccountInfoProps {
  user: User;
  onUpdateUser: (userId: number, userData: Partial<User>) => void;
  onDeleteUser?: (userId: number) => void;
}

const AccountInfo: React.FC<AccountInfoProps> = ({ user, onUpdateUser, onDeleteUser }) => {
  const [editedUser, setEditedUser] = useState({
    ...user,
    auth_method: user.auth_method ?? AuthMethod.GOOGLE,
    subscription_plan: user.subscription_plan ?? SubscriptionPlan.SERVICED,
  });
  
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  
  const { showToast } = useToast(); // Use the useToast hook

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setEditedUser(prev => ({ ...prev, [name]: value }));
  };

  const handleToggleChange = (checked: boolean) => {
    setEditedUser(prev => ({ ...prev, listener_active: checked }));
    showToast(`Listener ${checked ? 'activated' : 'deactivated'}`, 'info');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onUpdateUser(user.id, editedUser);
  };

  const handleDeleteClick = () => {
    setIsConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    setIsDeleting(true);
    onDeleteUser?.(user.id);
    setIsConfirmationOpen(false);
    showToast('Account deleted successfully', 'info');
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={editedUser.email}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <div>
          <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-1">Role</label>
          <select
            id="role"
            name="role"
            value={editedUser.role}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          >
            {Object.values(UserRole).map((role) => (
              <option key={role} value={role}>{role}</option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="auth_method" className="block text-sm font-medium text-gray-700 mb-1">Auth Method</label>
          <select
            id="auth_method" // Updated this line
            name="auth_method" // Updated this line
            value={editedUser.auth_method} // Updated this line
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          >
            {Object.values(AuthMethod).map((method) => (
              <option key={method} value={method}>{method}</option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="subscription_plan" className="block text-sm font-medium text-gray-700 mb-1">Subscription Plan</label>
          <select
            id="subscription_plan"
            name="subscription_plan"
            value={editedUser.subscription_plan || ''}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">Select a plan</option>
            {Object.values(SubscriptionPlan).map((plan) => (
              <option key={plan} value={plan}>{plan}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <Toggle
          id="listener_active"
          checked={editedUser.listener_active}
          onChange={handleToggleChange}
          label="Listener Active"
        />
      </div>

      <div className="flex justify-between items-center pt-4 border-t border-gray-200">
        {user.id && (
          <button
            type="button"
            onClick={handleDeleteClick}
            className={`px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-300 flex items-center ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isDeleting}
          >
            <TrashIcon className="h-5 w-5 mr-2" />
            {isDeleting ? 'Deleting...' : 'Delete User'}
          </button>
        )}

        <button
          type="submit"
          className="px-4 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-md hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
        >
          Save Changes
        </button>
      </div>

      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this user? This action cannot be undone."
        options={[{ value: 'delete', label: 'Yes, delete this user' }]}
      />
    </form>
  );
};

export default AccountInfo;
