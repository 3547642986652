import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const ROICalculator: React.FC = () => {
  const [employees, setEmployees] = useState<string>('1');
  const [hourlyRate, setHourlyRate] = useState<string>('30');
  const [weeklyTimeSaved, setWeeklyTimeSaved] = useState<number>(0);
  const [annualTimeSaved, setAnnualTimeSaved] = useState<number>(0);
  const [annualMoneySaved, setAnnualMoneySaved] = useState<number>(0);

  useEffect(() => {
    const numEmployees = parseInt(employees) || 0;
    const rate = parseFloat(hourlyRate) || 0;
    
    const weeklyEmailTime = 40 * 0.28; // 28% of workweek spent on emails
    const potentialWeeklyTimeSaved = weeklyEmailTime * 0.5; // 50% time saved with Inktuitive
    
    const calculatedWeeklyTimeSaved = potentialWeeklyTimeSaved * numEmployees;
    setWeeklyTimeSaved(calculatedWeeklyTimeSaved);

    const calculatedAnnualTimeSaved = calculatedWeeklyTimeSaved * 52;
    setAnnualTimeSaved(calculatedAnnualTimeSaved);

    const calculatedAnnualMoneySaved = calculatedAnnualTimeSaved * rate;
    setAnnualMoneySaved(calculatedAnnualMoneySaved);
  }, [employees, hourlyRate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'employees') {
      setEmployees(value);
    } else if (name === 'hourlyRate') {
      setHourlyRate(value);
    }
  };

  return (
    <div className="bg-white shadow-xl rounded-xl p-8 max-w-3xl mx-auto">
      <h3 className="text-3xl font-bold text-indigo-700 mb-6 text-center">ROI Calculator</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div>
          <label htmlFor="employees" className="block text-lg font-medium text-gray-700 mb-2">
            Number of Employees
          </label>
          <input
            type="text"
            id="employees"
            name="employees"
            value={employees}
            onChange={handleInputChange}
            pattern="\d*"
            className="input input-bordered input-lg w-full bg-gray-50 text-gray-800"
            inputMode="numeric"
            placeholder="Enter number of employees"
          />
        </div>
        <div>
          <label htmlFor="hourlyRate" className="block text-lg font-medium text-gray-700 mb-2">
            Average Hourly Rate (NZD)
          </label>
          <input
            type="text"
            id="hourlyRate"
            name="hourlyRate"
            value={hourlyRate}
            onChange={handleInputChange}
            pattern="\d*"
            className="input input-bordered input-lg w-full bg-gray-50 text-gray-800"
            inputMode="numeric"
            placeholder="Enter hourly rate"
          />
        </div>
      </div>
      <motion.div
        className="bg-indigo-50 rounded-lg p-6 shadow-inner"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h4 className="text-2xl font-semibold text-indigo-800 mb-4 text-center">
          Your Potential Savings with Inktuitive Email
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
          <div className="bg-white rounded-md p-4 shadow">
            <p className="text-lg text-gray-600 mb-1">Weekly Time Saved</p>
            <p className="text-3xl font-bold text-indigo-600">{weeklyTimeSaved.toFixed(1)} hours</p>
          </div>
          <div className="bg-white rounded-md p-4 shadow">
            <p className="text-lg text-gray-600 mb-1">Annual Time Saved</p>
            <p className="text-3xl font-bold text-indigo-600">{annualTimeSaved.toFixed(0)} hours</p>
          </div>
          <div className="bg-white rounded-md p-4 shadow">
            <p className="text-lg text-gray-600 mb-1">Annual Money Saved</p>
            <p className="text-3xl font-bold text-green-600">${annualMoneySaved.toLocaleString(undefined, {maximumFractionDigits:0})} NZD</p>
          </div>
        </div>
      </motion.div>
      <p className="mt-4 text-sm text-gray-600 text-center italic">
        *Estimates based on research showing 28% of workweek spent on emails and potential 50% time savings with Inktuitive Email.
      </p>
    </div>
  );
};

export default ROICalculator;