import React, { useEffect } from "react";
import { motion } from "framer-motion";
import PageLayout from "../components/layout/PageLayout";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

// Import your images
import EnvelopeMailBgImage from "../images/background/envelope-mail.png";
import AuthenticationWorkflowImage from "../images/email-assets/onboarding/Authentication_workflow.png";
import PersonalContextSettingImage from "../images/email-assets/onboarding/personal_context_setting.png";
import SortingCategoriesSettingsImage from "../images/email-assets/onboarding/Sorting_categories_settings.png";
import CustomRuleSettingImage from "../images/email-assets/onboarding/custom_rule_setting.png";
import PriorityInboxSettingImage from "../images/email-assets/onboarding/priority_inbox_setting.png";
import ContactSupportImage from "../images/email-assets/onboarding/contact_support.png";


// Define types for our step structure
interface OnboardingStep {
  title: string;
  description: string[];
  image: string;
}

const onboardingSteps: OnboardingStep[] = [
  {
    title: "Complete Your Registration",
    description: [
      "Click the 'Complete Your Registration' button in the email.",
      "On the Inktuitive login page, click 'Login via Google'.",
      "You may see a security warning. If so, click 'Advanced', then 'Go to Inktuitive (unsafe)'.",
      "Complete the Google login process.",
    ],
    image: AuthenticationWorkflowImage,
  },
  {
    title: "Personalize Your AI",
    description: [
      "Add your name, role, and personal context to help our AI understand you better.",
    ],
    image: PersonalContextSettingImage,
  },
  {
    title: "Set Up Email Categories",
    description: [
      "Add existing Gmail labels using the dropdown box at the bottom of the category list, or select from our suggested categories",
      "Create custom labels as needed",
      "Mark categories for your priority inbox and auto-drafted responses",
      "Example: Urgent categories likely require a response, but notifications will not.",
    ],
    image: SortingCategoriesSettingsImage,
  },
  {
    title: "Refine Category Rules",
    description: [
      "Set specific rules to improve AI sorting accuracy.",
    ],
    image: CustomRuleSettingImage,
  },
  {
    title: "Set Up Priority Inbox",
    description: [
      "Configure your priority inbox categories to focus on the most important emails, and generate draft-responses where appropriate.",
    ],
    image: PriorityInboxSettingImage,
  },
  {
    title: "Contact Support for Activation",
    description: [
      "Contact our support team to let us know you're ready to have your AI assistant activated.",
      "We will link you to your organization (if relevant) and set your assistant to live.",
      "Remember to set up your HTML signature or ask support to do this for you.",
    ],
    image: ContactSupportImage,
  },
];

const OnboardingPage: React.FC = () => {
  useEffect(() => {
    document.title = "Onboarding Guide | Inktuitive Email";
  }, []);

  useEffect(() => {
    document.body.classList.add("scrollable-page");
    return () => {
      document.body.classList.remove("scrollable-page");
    };
  }, []);

  return (
    <PageLayout>
      {/* Hero Banner */}
      <motion.div
        className="relative bg-gradient-to-br from-indigo-600 to-purple-600 text-white py-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div
          className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat opacity-10"
          style={{
            backgroundImage: `url(${EnvelopeMailBgImage})`,
          }}
        ></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl mb-4">
            Onboarding Guide
          </h1>
          <p className="text-xl max-w-3xl">
            Welcome to Inktuitive Email! Follow this guide to set up your account and start revolutionizing your email management.
          </p>
        </div>
      </motion.div>

      {/* Main Content */}
<motion.div
  className="flex-grow bg-gradient-to-br from-indigo-50 to-purple-50"
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ duration: 0.5, delay: 0.2 }}
>
  <div className="py-12 sm:py-20 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto w-full">
      {onboardingSteps.map((step, index) => (
        <motion.div
          key={index}
          className="mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 + index * 0.1 }}
        >
          <h2 className="text-3xl font-semibold mb-6 text-gray-800">
            Step {index + 1}: {step.title}
          </h2>
          <div className="flex flex-col md:flex-row items-start md:items-center gap-8">
            <div className="w-full md:w-1/2">
              <ul className="list-disc pl-5 text-gray-600 space-y-2">
                {step.description.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="w-full md:w-1/2">
              <img
                src={step.image}
                alt={step.title}
                className="w-full rounded-lg shadow-lg"
              />
            </div>
          </div>
        </motion.div>
      ))}
      
      {/* Reminder about HTML signature */}
      <motion.div
        className="mt-12 p-6 bg-yellow-50 rounded-lg shadow-md"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.9 }}
      >
        <h3 className="text-xl font-semibold mb-2 text-yellow-800">Reminder:</h3>
        <p className="text-yellow-700">
          Don't forget to set up your HTML signature in the Signature Tab. If you need assistance with this, our support team will be happy to help during the activation process.
        </p>
      </motion.div>
    </div>
  </div>
</motion.div>

      {/* CTA Section */}
      <motion.section
        className="py-20 bg-gradient-to-r from-indigo-600 to-purple-600 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-extrabold mb-6">
            Ready to Get Started?
          </h2>
          <p className="text-xl mb-8">
            Now that you've seen the onboarding process, it's time to experience the power of Inktuitive Email for yourself.
          </p>
          <div className="flex justify-center space-x-4">
            <a
              href="/login"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-50 transition-colors duration-300"
            >
              Log In
              <ArrowRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </a>
            <a
              href="/contact"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition-colors duration-300"
            >
              Contact Support
            </a>
          </div>
        </div>
      </motion.section>
    </PageLayout>
  );
};

export default OnboardingPage;