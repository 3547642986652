import React from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Category } from '../../services/emailSettingsApi';

interface SuggestedCategoriesProps {
  categories: Category[];
  onAddCategory: (category: string) => void;
}

const suggestedCategories = [
  'Urgent', 'Internal', 'Sales', 'Client', 'Notifications',
  'News & Updates', 'Support', 'Travel', 'Personal', 'Finance', 'Other'
];

const SuggestedCategories: React.FC<SuggestedCategoriesProps> = ({ categories, onAddCategory }) => {
  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-2">Suggested Categories</h3>
      <div className="flex flex-wrap gap-1 sm:gap-2 mb-4">
        {suggestedCategories.map((category) => (
          <button
            key={category}
            onClick={() => onAddCategory(category)}
            className={`btn btn-xs sm:btn-sm ${
              categories.some((c) => c.name === category)
                ? 'btn-success btn-outline'
                : 'btn-ghost'
            }`}
          >
            {category}
            {categories.some((c) => c.name === category) && (
              <CheckIcon className="inline-block w-3 h-3 sm:w-4 sm:h-4 ml-1" />
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SuggestedCategories;