import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Toggle from '../../components/layout/Toggle';
import { toggleGmailListener, getGmailListenerStatus } from '../../services/adminApi';
import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';

const AdminSettings: React.FC = () => {
  const [isListenerActive, setIsListenerActive] = useState(false);
  const [importStatus, setImportStatus] = useState<string | null>(null);
  const [exportStatus, setExportStatus] = useState<string | null>(null);

  useEffect(() => {
    const fetchListenerStatus = async () => {
      try {
        const status = await getGmailListenerStatus();
        setIsListenerActive(status.active);
      } catch (error) {
        console.error('Error fetching Gmail listener status:', error);
      }
    };
    fetchListenerStatus();
  }, []);

  const handleToggleChange = async (checked: boolean) => {
    try {
      const updatedStatus = await toggleGmailListener(checked);
      setIsListenerActive(updatedStatus.active);
      console.log(`Gmail listener ${updatedStatus.active ? 'activated' : 'deactivated'}`);
    } catch (error) {
      console.error('Error toggling global Gmail listener:', error);
      // Fetch current status in case of error
      try {
        const currentStatus = await getGmailListenerStatus();
        setIsListenerActive(currentStatus.active);
      } catch (statusError) {
        console.error('Error fetching current Gmail listener status:', statusError);
      }
    }
  };

  const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setImportStatus('Importing...');
    try {
      const formData = new FormData();
      formData.append('file', file);
      
      // TODO: Replace with actual API call
      // const response = await api.importSettings(formData);
      
      // Simulating API call
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      setImportStatus('Import successful');
      // TODO: Update local state with imported settings
    } catch (error) {
      console.error('Error importing settings:', error);
      setImportStatus('Import failed');
    }
  };

  const handleExport = async () => {
    setExportStatus('Exporting...');
    try {
      // TODO: Replace with actual API call
      // const response = await api.exportSettings();
      
      // Simulating API call
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Simulating file download
      const blob = new Blob(['Simulated export data'], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'admin_settings_export.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      setExportStatus('Export successful');
    } catch (error) {
      console.error('Error exporting settings:', error);
      setExportStatus('Export failed');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="w-full"
    >
      <h2 className="text-2xl font-bold mb-6">Admin Settings</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-xl font-semibold text-indigo-600 mb-4">Global Settings</h3>
          <div className="flex items-center justify-between">
            <span className="text-gray-700">Gmail Listener Status (Whole App)</span>
            <Toggle
              checked={isListenerActive}
              onChange={handleToggleChange}
              id="gmail-listener-toggle"
              label=""
            />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-xl font-semibold text-indigo-600 mb-4">Import/Export (coming soon...)</h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="import-settings" className="flex items-center justify-center w-full px-4 py-2 bg-white text-indigo-600 rounded-md border border-indigo-600 cursor-pointer hover:bg-indigo-50 transition duration-300">
                <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
                Import
              </label>
              <input
                id="import-settings"
                type="file"
                accept=".json"
                onChange={handleImport}
                className="hidden"
              />
              {importStatus && <p className="mt-2 text-sm text-gray-600">{importStatus}</p>}
            </div>
            <button
              onClick={handleExport}
              className="flex items-center justify-center w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300"
            >
              <ArrowDownTrayIcon className="w-5 h-5 mr-2" />
              Export
            </button>
            {exportStatus && <p className="mt-2 text-sm text-gray-600">{exportStatus}</p>}
          </div>
        </div>
      </div>

      {/* Additional settings sections can be added here */}
    </motion.div>
  );
};

export default AdminSettings;