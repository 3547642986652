// File: frontend/src/services/adminApi.ts

import api from './api';
import { User } from '../types/user';
import { Category, EmailSortingRule, EmailCategoryConfigUpdate } from './emailSettingsApi';
import { LogPagination } from '../types/log';


interface GetLogsParams {
  page: number;
  page_size: number;
  search?: string;
  level?: string;
  service?: string;
  entry_type?: string;
}

export const getLogs = async (params: GetLogsParams): Promise<LogPagination> => {
  try {
    const response = await api.get('/admin/logs', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching logs:', error);
    throw error;
  }
};

export class APIError extends Error {
  constructor(public status: number, message: string) {
    super(message);
    this.name = 'APIError';
  }
}


export const getUsers = async (): Promise<User[]> => {
    const response = await api.get('/admin/users');
    return response.data;
  };
  
  export const createUser = async (userData: Partial<User>): Promise<User> => {
    const response = await api.post('/admin/users', userData);
    return response.data;
  };
  
  export const updateUser = async (userId: number, userData: Partial<User>): Promise<User> => {
    const response = await api.put(`/admin/users/${userId}`, userData);
    return response.data;
  };
  
  export const deleteUser = async (userId: number): Promise<void> => {
    await api.delete(`/admin/users/${userId}`);
  };

export const getSystemConfigs = async (): Promise<Record<string, string>> => {
  const response = await api.get('/admin/system-configs');
  return response.data;
};

export const updateSystemConfig = async (key: string, value: string): Promise<void> => {
  await api.put('/admin/system-configs', { key, value });
};

// New functions for user settings

export const getUserContextSettings = async (userId: number) => {
    const response = await api.get(`/user_settings/${userId}/context`);
    return response.data;
  };
  
  export const updateUserContextSettings = async (userId: number, settings: any) => {
    const response = await api.put(`/user_settings/${userId}/context`, settings);
    return response.data;
  };
  
  export const getEmailSortingSettings = async (userId: number) => {
    const response = await api.get(`/user_settings/${userId}/email-sorting`);
    return response.data;
  };
  
  export const updateEmailSortingSettings = async (userId: number, settings: any) => {
    const response = await api.put(`/user_settings/${userId}/email-sorting`, settings);
    return response.data;
  };
  
// Get response settings for a user
export const getResponseSettings = async (userId: number) => {
    const response = await api.get(`/admin/response-settings/${userId}`);
    return response.data;
};

// Create new response settings for a user
export const createResponseSettings = async (settings: any) => {
    const response = await api.post(`/admin/response-settings`, settings);
    return response.data;
};

// Update response settings for a user
export const updateResponseSettings = async (userId: number, settings: any) => {
    const response = await api.put(`/admin/response-settings/${userId}`, settings);
    return response.data;
};

// Delete response settings for a user
export const deleteResponseSettings = async (userId: number) => {
    const response = await api.delete(`/admin/response-settings/${userId}`);
    return response.data;
};


// Category Response Settings APIs

// Get category-specific response settings for a user
export const getCategoryResponseSettings = async (userId: number) => {
    const response = await api.get(`/admin/response-settings/categories/${userId}`);
    return response.data;
};

// Create new category-specific response settings
export const createCategoryResponseSettings = async (settings: any) => {
    const response = await api.post(`/admin/response-settings/categories`, settings);
    return response.data;
};

const safeEncodeCategoryName = (categoryName: string) => {
  return encodeURIComponent(categoryName.replace('/', '___SLASH___'));
};

const safeDecodeCategoryName = (encodedName: string) => {
  return decodeURIComponent(encodedName).replace('___SLASH___', '/');
};

export const updateCategoryResponseSettings = async (userId: number, categoryName: string, settings: any) => {
  const encodedCategoryName = safeEncodeCategoryName(categoryName);
  const response = await api.put(`/admin/response-settings/categories/${userId}/${encodedCategoryName}`, settings);
  return {
      ...response.data,
      category: safeDecodeCategoryName(response.data.category)
  };
};

// Delete category-specific response settings
export const deleteCategoryResponseSettings = async (categoryId: number) => {
    const response = await api.delete(`/admin/response-settings/categories/${categoryId}`);
    return response.data;
};

export const clearAllCategoryResponseSettings = async (userId: number) => {
  const response = await api.delete(`/admin/response-settings/categories/user/${userId}`);
  return response.data;
};


  export const getUserSignature = async (userId: number): Promise<string> => {
    const response = await api.get(`/users/${userId}/signature`);
    return response.data.signature;
  };
  
  export const updateUserSignature = async (userId: number, signatureData: { signature: string }): Promise<void> => {
    await api.put(`/users/${userId}/signature`, signatureData);
  };
  
  export const getBusinessContextSettings = async (userId: number) => {
    const response = await api.get(`/user_settings/${userId}/business-context`);
    return response.data;
  };
  
  export const updateBusinessContextSettings = async (userId: number, settings: any) => {
    const response = await api.put(`/user_settings/${userId}/business-context`, settings);
    return response.data;
  };

// New functions for email sorting settings management
export const getEmailCategories = async (userId: number): Promise<Category[]> => {
    const response = await api.get(`/admin/users/${userId}/email-categories`);
    return response.data;
  };
  
  export const updateEmailCategories = async (configUpdate: EmailCategoryConfigUpdate, userId: number): Promise<void> => {
    await api.put(`/admin/users/${userId}/email-categories`, configUpdate);
  };
  
  export const getGmailLabels = async (userId: number): Promise<string[]> => {
    const response = await api.get(`/admin/users/${userId}/gmail-labels`);
    return response.data;
  };
  
  export const createGmailLabel = async (labelName: string, userId: number): Promise<{ message: string; label?: any }> => {
    const response = await api.post(`/admin/users/${userId}/gmail-labels`, { name: labelName });
    return response.data;
  };
  
  export const removeEmailCategory = async (userId: number, categoryName: string, removeFromGmail: boolean): Promise<{ success: boolean; message: string }> => {
    const response = await api.delete(`/admin/users/${userId}/email-categories/${encodeURIComponent(categoryName)}`, {
      params: { remove_from_gmail: removeFromGmail }
    });
    return response.data;
  };
  
  // New functions for managing email category rules
  export const getCategoryRules = async (userId: number, categoryName: string): Promise<EmailSortingRule[]> => {
    const response = await api.get(`/admin/users/${userId}/email-categories/${encodeURIComponent(categoryName)}/rules`);
    return response.data;
  };

  export const addRuleToCategory = async (userId: number, categoryName: string, rule: EmailSortingRule): Promise<EmailSortingRule> => {
    const response = await api.post(`/admin/users/${userId}/email-categories/${encodeURIComponent(categoryName)}/rules`, rule);
    return response.data;
  };
  
  export const updateRuleInCategory = async (userId: number, categoryName: string, ruleId: number, rule: EmailSortingRule): Promise<EmailSortingRule> => {
    const response = await api.put(`/admin/users/${userId}/email-categories/${encodeURIComponent(categoryName)}/rules/${ruleId}`, rule);
    return response.data;
  };
  
  export const removeRuleFromCategory = async (userId: number, categoryName: string, ruleId: number): Promise<{ message: string }> => {
    const response = await api.delete(`/admin/users/${userId}/email-categories/${encodeURIComponent(categoryName)}/rules/${ruleId}`);
    return response.data;
  };



// Global settings functions

export const getGlobalSettings = async () => {
  const response = await api.get('/admin/global-settings');
  return response.data;
};

export const updateGlobalSettings = async (settings: any) => {
  const response = await api.put('/admin/global-settings', settings);
  return response.data;
};

export const toggleGmailListener = async (active: boolean): Promise<{ active: boolean }> => {
    await api.post('/admin/toggle-gmail-listener', active);
    return getGmailListenerStatus(); // Immediately fetch and return the updated status
  };
  
  export const getGmailListenerStatus = async (): Promise<{ active: boolean }> => {
    const response = await api.get('/admin/gmail-listener-status');
    return response.data;
  };

const apiFunctions = {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  getSystemConfigs,
  updateSystemConfig,
  getUserContextSettings,
  updateUserContextSettings,
  getEmailSortingSettings,
  updateEmailSortingSettings,
  getResponseSettings,
  updateResponseSettings,
  getBusinessContextSettings,
  updateBusinessContextSettings,
  getEmailCategories,
  updateEmailCategories,
  getGmailLabels,
  createGmailLabel,
  removeEmailCategory,
  getCategoryRules,
  addRuleToCategory,
  updateRuleInCategory,
  removeRuleFromCategory,
  clearAllCategoryResponseSettings,
  getGlobalSettings,
  updateGlobalSettings,
  getUserSignature,
  updateUserSignature,
  toggleGmailListener,
  getGmailListenerStatus
};

export default apiFunctions;
