// File: frontend/src/components/admin/UserModal.tsx

import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { User } from '../../types/user';
import AccountInfo from './AccountInfo';
import UserContextSettings from '../settings/UserContextSettings';
import EmailSortingSettings from '../settings/EmailSortingSettings';
import ResponseSettings from '../settings/ResponseSettings';
import BusinessContextSettings from '../settings/BusinessContextSettings';
import { deleteUser } from '../../services/api';  
import businessSettingsApi from '../../services/businessSettingsApi';
import { Organization, BusinessContextSettings as BusinessContextSettingsType } from '../../types/settings'; 
import { motion, AnimatePresence } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/24/outline'; 
import axios from 'axios';

interface UserModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User | null;
  onFormSubmit: (userData: Partial<User>) => void;
  onUpdateUser: (userId: number, userData: Partial<User>) => void;
}

const UserModal: React.FC<UserModalProps> = ({
  isOpen,
  onClose,
  user,
  onFormSubmit,
  onUpdateUser,
}) => {
  const [activeTab, setActiveTab] = useState('account');
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [businessContextSettings, setBusinessContextSettings] = useState<BusinessContextSettingsType | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAccountSaved, setIsAccountSaved] = useState(false);

  const fetchBusinessContext = useCallback(async (organizationId: number) => {
    try {
      setLoading(true);
      const fetchedSettings = await businessSettingsApi.getOrganizationBusinessContext(organizationId);
      setBusinessContextSettings(fetchedSettings || defaultBusinessContext(organizationId));
    } catch (error) {
      setError('Failed to fetch business context settings');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchOrganization = useCallback(async (userId: number) => {
    try {
      const fetchedOrganization = await businessSettingsApi.getOrganizationByUserId(userId);
      if (fetchedOrganization) {
        setOrganization(fetchedOrganization);
        await fetchBusinessContext(fetchedOrganization.id);
      } else {
        // If no organisation is assigned, allow the user to assign one
        setOrganization(null);
        setError(null); // Clear error as this is expected
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        // User has no assigned organization
        setOrganization(null);
        setError(null); // Clear error as this is expected
      } else {
        setError('Error fetching organization.');
      }
    }
  }, [fetchBusinessContext]);

  useEffect(() => {
    if (isOpen) {
      setError(null); // Reset error on modal open
      setLoading(false); // Reset loading state

      if (user && user.id) {
        setIsAccountSaved(true);
        setActiveTab('account');
        fetchOrganization(user.id);
      } else {
        setIsAccountSaved(false);
        setActiveTab('account');
        setOrganization(null);
        setBusinessContextSettings(null);
      }
    }
  }, [isOpen, user, fetchOrganization]);

  useEffect(() => {
    fetchAllOrganizations();
  }, []);

  const fetchAllOrganizations = async () => {
    try {
      const orgs = await businessSettingsApi.getOrganizations();
      setAllOrganizations(orgs);
    } catch (error) {
      console.error('Error fetching all organizations:', error);
    }
  };

  const defaultBusinessContext = (organizationId: number): BusinessContextSettingsType => ({
    organization_id: organizationId,
    industry: '',
    key_offerings: [],
    associated_businesses: [],
    additional_context: '',
  });

  const handleAddUserToOrganization = async () => {
    if (user && selectedOrganization) {
      try {
        setLoading(true);
        await businessSettingsApi.addUserToOrganization(selectedOrganization.id, user.id);
        setOrganization(selectedOrganization);
        await fetchBusinessContext(selectedOrganization.id);
        setActiveTab('businessContext');
      } catch (error) {
        console.error('Error adding user to organization:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSaveAccountInfo = (userId: number, userData: Partial<User>) => {
    if (userId) {
      onUpdateUser(userId, userData);
    } else {
      onFormSubmit(userData);
    }
    setIsAccountSaved(true);
  };

  const handleDeleteUser = async (userId: number) => {
    try {
      setLoading(true);
      await deleteUser(userId);
      onClose();
    } catch (error) {
      console.error('Error deleting user:', error);
    } finally {
      setLoading(false);
    }
  };

    // Close modal on 'esc' key press
    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && isOpen) {
          onClose();
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [isOpen, onClose]);

  const tabs = [
    { id: 'account', label: 'Account Info' },
    { id: 'userContext', label: 'User Context', disabled: !isAccountSaved },
    { id: 'emailSorting', label: 'Email Sorting', disabled: !isAccountSaved },
    { id: 'response', label: 'Response Settings', disabled: !isAccountSaved },
    { id: 'businessContext', label: 'Business Context', disabled: !isAccountSaved },
  ];

  if (!isOpen) return null;
  if (loading) return <div>Loading...</div>;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="modal-container mt-16"
        >
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
            className="modal-content"
          >
            {/* Modal Header with Close Button */}
            <div className="modal-header">
              <h3 className="text-2xl font-bold text-gray-800">
                {user?.id ? `Edit User: ${user.email}` : 'Create New User'}
              </h3>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 transition duration-150"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            {/* Scrollable Content */}
            <div className="p-6 space-y-6">
              {loading ? (
                <div className="text-center py-8">Loading...</div>
              ) : (
                <>
                  {error && <div className="text-red-500 mb-4">{error}</div>}
                  <div className="mb-6">
                    <nav className="flex space-x-2">
                      {tabs.map((tab) => (
                        <button
                          key={tab.id}
                          onClick={() => setActiveTab(tab.id)}
                          disabled={tab.disabled}
                          className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                            activeTab === tab.id
                              ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white'
                              : tab.disabled
                              ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                              : 'text-gray-700 hover:bg-gray-100'
                          }`}
                        >
                          {tab.label}
                        </button>
                      ))}
                    </nav>
                  </div>

                  <div className="mt-6">
                    {activeTab === 'account' && (
                      <AccountInfo
                        user={user || ({} as User)}
                        onUpdateUser={handleSaveAccountInfo}
                        onDeleteUser={handleDeleteUser}
                      />
                    )}
                    {activeTab === 'userContext' && user && <UserContextSettings userId={user.id} />}
                    {activeTab === 'emailSorting' && user && <EmailSortingSettings userId={user.id} />}
                    {activeTab === 'response' && user && <ResponseSettings userId={user.id} />}
                    {activeTab === 'businessContext' && (
                      <>
                        {organization ? (
                          <BusinessContextSettings
                            settings={businessContextSettings || defaultBusinessContext(organization.id)}
                            organizationId={organization.id}
                            organizationName={organization.name || 'Unknown'}
                            organizationDomain={organization.domain || 'Unknown'}
                            onSave={async () => Promise.resolve()} // No-op for read-only mode
                            readOnly={true} // Read-only mode
                          />
                        ) : (
                          <div className="bg-gray-50 rounded-lg p-6">
                            <h4 className="text-lg font-semibold mb-4">Assign User to Organization</h4>
                            <Select
                              options={allOrganizations.map((org) => ({
                                value: org.id,
                                label: org.name,
                              }))}
                              onChange={(option) =>
                                setSelectedOrganization(
                                  allOrganizations.find((org) => org.id === option?.value) || null
                                )
                              }
                              placeholder="Select an organization"
                              className="mb-4"
                            />
                            <button
                              className="px-4 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-md hover:from-indigo-700 hover:to-purple-700 transition duration-300"
                              onClick={handleAddUserToOrganization}
                              disabled={!selectedOrganization}
                            >
                              Assign to Organization
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default UserModal;
