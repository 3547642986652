// frontend/src/types/user.ts

export enum UserRole {
    USER = "user",
    ADMIN = "admin"
}

export enum AuthMethod {
    PASSWORD = "PASSWORD",
    GOOGLE = "GOOGLE",
    MICROSOFT = "MICROSOFT",
    SSO = "SSO"
}

export enum SubscriptionPlan {
    BASIC = "basic",
    PREMIUM = "premium",
    BUSINESS = "business",
    SERVICED = "serviced"
}

export interface OrganizationInUser {
  id: number;
  name: string;
}

export interface User {
  id: number;
  email: string;
  password?: string;  
  role: UserRole;
  auth_method?: AuthMethod;
  subscription_plan?: SubscriptionPlan;
  organization?: OrganizationInUser;
  listener_active: boolean; 
  organizationName?: string; // We'll use this for convenience
}
