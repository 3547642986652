// src/pages/Support.tsx

import React from 'react';
import { motion } from 'framer-motion';
import { NavLink, Routes, Route } from 'react-router-dom';
import ContactSupportForm from '../../components/widgets/ContactSupportForm';
import FeedbackForm from '../../components/widgets/FeedbackForm';

const Support: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gradient-to-br from-indigo-600 to-purple-600 p-6"
    >
      <div className="bg-white rounded-xl shadow-2xl p-8 mb-10">
        <h1 className="text-4xl font-extrabold text-gray-800 mb-6">Support</h1>

        <nav className="flex space-x-2 mb-8">
          <NavLink
            to="/support"
            end
            className={({ isActive }) =>
              `px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                isActive
                  ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white'
                  : 'text-gray-700 hover:bg-gray-100'
              }`
            }
          >
            Contact Support
          </NavLink>
          <NavLink
            to="/support/feedback"
            className={({ isActive }) =>
              `px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                isActive
                  ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white'
                  : 'text-gray-700 hover:bg-gray-100'
              }`
            }
          >
            Feedback
          </NavLink>
        </nav>

        <div className="bg-white rounded-lg p-6">
          <Routes>
            <Route index element={<ContactSupportForm />} />
            <Route path="feedback" element={<FeedbackForm />} />
          </Routes>
        </div>
      </div>
    </motion.div>
  );
};

export default Support;