import React, { useEffect, useState } from 'react';
import { Routes, Route, NavLink, Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { RootState, AppDispatch } from '../../store/store';
import { fetchUserData } from '../../store/slices/authSlice';
import EmailSortingSettings from '../../components/settings/EmailSortingSettings';
import UserContextSettings from '../../components/settings/UserContextSettings';
import SignatureSettings from '../../components/settings/SignatureSettings';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

const Settings: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.auth.user);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const isActive = (path: string) => {
    if (path === '/admin') {
      return location.pathname === '/admin' || location.pathname === '/admin/dashboard';
    }
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    if (!user) {
      dispatch(fetchUserData());
    }
  }, [dispatch, user]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  if (!user) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-600 to-purple-600 p-4 sm:p-6 flex items-center justify-center">
        <div className="bg-white rounded-xl shadow-2xl p-6 sm:p-8">
          <p className="text-xl font-semibold text-gray-800">Loading...</p>
        </div>
      </div>
    );
  }

  const navItems = [
    { path: 'user-context', label: 'User Context' },
    { path: 'email-sorting', label: 'Email Sorting' },
    { path: 'signature', label: 'Signature' },
  ];

  const getCurrentPageTitle = () => {
    const currentPath = location.pathname.split('/').pop();
    const currentItem = navItems.find(item => item.path === currentPath);
    return currentItem ? currentItem.label : 'Settings';
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gradient-to-br from-indigo-600 to-purple-600 p-4 sm:p-6"
    >
      <div className="bg-white rounded-xl shadow-2xl p-6 sm:p-8 mb-6 sm:mb-10">
        <h1 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-6 sm:mb-8">Settings</h1>

        <div className="mb-6 sm:mb-8">
          <div className="sm:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="w-full flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span>{getCurrentPageTitle()}</span>
              <ChevronDownIcon className={`ml-2 h-5 w-5 transition-transform ${isMenuOpen ? 'transform rotate-180' : ''}`} />
            </button>

            {isMenuOpen && (
              <div className="mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1" role="menu" aria-orientation="vertical">
                  {navItems.map((item) => (
                    <NavLink
                      key={item.path}
                      onClick={(e) => isActive(item.path) && e.preventDefault()}
                      to={`/settings/${item.path}`}
                      className={({ isActive }) =>
                        `block px-4 py-2 text-sm ${isActive ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                        } hover:bg-gray-100 hover:text-gray-900`
                      }
                      role="menuitem"
                    >
                      {item.label}
                    </NavLink>
                  ))}
                </div>
              </div>
            )}
          </div>

          <nav className="hidden sm:flex space-x-2">
            {navItems.map((item) => (
              <NavLink
                key={item.path}
                onClick={(e) => {
                  isActive(`/settings/${item.path}`) &&
                    e.preventDefault();

                }}
                to={`/settings/${item.path}`}
                className={({ isActive: linkActive }) =>
                  `px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${isActive(`/settings/${item.path}`)
                    ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white'
                    : 'text-gray-700 hover:bg-gray-100'
                  }`
                }
              >
                {item.label}
              </NavLink>
            ))}
          </nav>
        </div>

        <div className="bg-gray-50 rounded-lg p-4 sm:p-6">
          <Routes>
            <Route index element={<Navigate to="user-context" replace />} />
            <Route path="user-context" element={<UserContextSettings userId={user.id} />} />
            <Route path="email-sorting" element={<EmailSortingSettings userId={user.id} />} />
            <Route path="signature" element={<SignatureSettings userId={user.id} />} />
          </Routes>
        </div>
      </div>
    </motion.div>
  );
};

export default Settings;
