import React, { useState, useEffect, useCallback } from 'react';
import Analytics from '../Analytics';
import { MagnifyingGlassIcon, UserIcon, PencilIcon, CurrencyDollarIcon, ClockIcon, ChartBarIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Switch } from '@headlessui/react';
import { getTotalAnalytics, getUsers, getOrganizations, getUserAnalytics, getOrganizationAnalytics, getAllEmailsProcessed } from '../../services/api';
import TimeSeriesChart from './TimeSeriesChart';

const AdminDashboard: React.FC = () => {
  const [totalAnalytics, setTotalAnalytics] = useState<any>(null);
  const [specificAnalytics, setSpecificAnalytics] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isUserSearch, setIsUserSearch] = useState(true);
  const [users, setUsers] = useState<any[]>([]);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState('last_30_days');
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [noDataAlert, setNoDataAlert] = useState<string | null>(null);
  const [timeSeriesInterval, setTimeSeriesInterval] = useState<'day' | 'week' | 'month'>('day');
  const [chartData, setChartData] = useState<any[]>([]);

  const calculateTimeSaved = (emailsProcessed: number, draftsWritten: number) => {
    const emailTime = emailsProcessed * 10; // 10 seconds per email
    const draftTime = draftsWritten * 5 * 60; // 5 minutes per draft, converted to seconds
    const totalSeconds = emailTime + draftTime;
    return Math.round(totalSeconds / 60); // Convert to minutes and round
  };

  const getWeekNumber = (date: Date) => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
  };

  const processTimeSeriesData = useCallback((interval: 'day' | 'week' | 'month', data: any[]) => {
    const groupedData: { [key: string]: any } = {};

    data.forEach(item => {
      const date = new Date(item.timestamp);
      let key: string;

      switch (interval) {
        case 'day':
          key = date.toISOString().slice(0, 10); // Group by day (YYYY-MM-DD)
          break;
        case 'week':
          const weekNumber = getWeekNumber(date);
          key = `${date.getFullYear()}-W${weekNumber.toString().padStart(2, '0')}`;
          break;
        case 'month':
          key = date.toISOString().slice(0, 7); // Group by month (YYYY-MM)
          break;
      }

      if (!groupedData[key]) {
        groupedData[key] = {
          timestamp: key,
          emails_processed: 0,
          emails_failed: 0,
          drafts_written: 0,
          total_cost: 0
        };
      }

      groupedData[key].emails_processed += item.emails_processed || 0;
      groupedData[key].emails_failed += item.emails_failed || 0;
      groupedData[key].drafts_written += item.drafts_written || 0;
      groupedData[key].total_cost += item.total_cost || 0;
    });

    return Object.values(groupedData).sort((a, b) => a.timestamp.localeCompare(b.timestamp));
  }, []);

  const fetchChartData = useCallback(async () => {
    try {
      const data = await getAllEmailsProcessed(
        selectedTimeframe,
        selectedOption?.id,
        isUserSearch ? 'user' : 'organization'
      );
      const processedData = processTimeSeriesData(timeSeriesInterval, data);
      setChartData(processedData);
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setError('Failed to load chart data. Please try again later.');
    }
  }, [selectedTimeframe, selectedOption, isUserSearch, timeSeriesInterval, processTimeSeriesData]);

  useEffect(() => {
    fetchChartData();
  }, [fetchChartData]);

  useEffect(() => {
    const fetchTotalAnalytics = async () => {
      try {
        const data = await getTotalAnalytics(selectedTimeframe);
        const timeSaved = calculateTimeSaved(data.emails_processed, data.drafts_written);
        setTotalAnalytics({ ...data, time_saved: timeSaved });
      } catch (error) {
        console.error('Error fetching total analytics:', error);
        setError('Failed to load analytics data. Please try again later.');
      }
    };

    fetchTotalAnalytics();
  }, [selectedTimeframe]);

  useEffect(() => {
    const fetchUsersAndOrganizations = async () => {
      try {
        const [fetchedUsers, fetchedOrganizations] = await Promise.all([getUsers(), getOrganizations()]);
        setUsers(fetchedUsers);
        setOrganizations(fetchedOrganizations);
      } catch (error) {
        console.error('Error fetching users and organizations:', error);
      }
    };

    fetchUsersAndOrganizations();
  }, []);

  useEffect(() => {
    const options = isUserSearch ? users : organizations;
    const filtered = options.filter(option => 
      option.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      option.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, isUserSearch, users, organizations]);

  const handleSearch = async () => {
    if (!selectedOption) return;

    try {
      let data;
      if (isUserSearch) {
        data = await getUserAnalytics(selectedOption.id, selectedTimeframe);
      } else {
        data = await getOrganizationAnalytics(selectedOption.id, selectedTimeframe);
      }
      if (data && Object.keys(data).length > 0) {
        const timeSaved = calculateTimeSaved(data.emails_processed, data.drafts_written);
        setSpecificAnalytics({ ...data, time_saved: timeSaved });
        setNoDataAlert(null);
      } else {
        setSpecificAnalytics({
          emails_processed: 0,
          drafts_written: 0,
          total_cost: 0,
          time_saved: 0,
          total_users: isUserSearch ? 1 : 0
        });
        setNoDataAlert(`No data available for the selected ${isUserSearch ? 'user' : 'organization'} in the specified timeframe.`);
      }
    } catch (error) {
      console.error('Error fetching specific analytics:', error);
      setError('Failed to load specific analytics data. Please try again later.');
      setSpecificAnalytics(null);
    }
    setShowDropdown(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setShowDropdown(value.length > 0);
    
    const options = isUserSearch ? users : organizations;
    const filtered = options.filter(option => 
      option.email?.toLowerCase().includes(value.toLowerCase()) ||
      option.name?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option);
    setSearchTerm(isUserSearch ? option.email : option.name);
    setShowDropdown(false);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-2xl font-bold mb-4">Admin Dashboard</h2>
      
      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left column */}
        <div>
          <div className="mb-6">
            <label htmlFor="timeframe" className="block text-sm font-medium text-gray-700">Select Timeframe</label>
            <select
              id="timeframe"
              value={selectedTimeframe}
              onChange={(e) => setSelectedTimeframe(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="today">Today</option>
              <option value="last_30_days">Last 30 Days</option>
              <option value="last_month">Last Month</option>
              <option value="this_year">This Year</option>
              <option value="all_time">All Time</option>
            </select>
          </div>

          {totalAnalytics && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">Total Analytics (All Users)</h3>
              <div className="grid grid-cols-2 gap-4">
                <AnalyticCard icon={<UserIcon className="h-5 w-5" />} title="Total Users" value={totalAnalytics.total_users} />
                <AnalyticCard icon={<MagnifyingGlassIcon className="h-5 w-5" />} title="Emails Processed" value={totalAnalytics.emails_processed} />
                <AnalyticCard icon={<PencilIcon className="h-5 w-5" />} title="Drafts Written" value={totalAnalytics.drafts_written} />
                <AnalyticCard icon={<CurrencyDollarIcon className="h-5 w-5" />} title="Total Cost" value={`$${totalAnalytics.total_cost.toFixed(2)}`} />
                <AnalyticCard icon={<ClockIcon className="h-5 w-5" />} title="Time Saved" value={`${totalAnalytics.time_saved} mins`} />
                <AnalyticCard icon={<ChartBarIcon className="h-5 w-5" />} title="Efficiency Gain" value={`${((totalAnalytics.time_saved / 60) / 8).toFixed(2)} days`} />
              </div>
            </div>
          )}

          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Specific Analytics</h3>
            <div className="flex items-center mb-2">
              <Switch
                checked={isUserSearch}
                onChange={(checked) => {
                  setIsUserSearch(checked);
                  setSearchTerm('');
                  setSelectedOption(null);
                  setShowDropdown(false);
                }}
                className={`${isUserSearch ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Toggle search type</span>
                <span className={`${isUserSearch ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
              </Switch>
              <span className="ml-2 text-sm">{isUserSearch ? 'User Search' : 'Organization Search'}</span>
            </div>
            <div className="flex items-center">
              <div className="relative flex-grow">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleInputChange}
                  placeholder={isUserSearch ? "Search by user email" : "Search by organization name"}
                  className="w-full px-4 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                {showDropdown && filteredOptions.length > 0 && (
                  <ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg max-h-60 overflow-auto">
                    {filteredOptions.map((option) => (
                      <li
                        key={option.id}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm"
                        onClick={() => handleOptionSelect(option)}
                      >
                        {isUserSearch ? option.email : option.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <button
                onClick={handleSearch}
                className="px-4 py-2 bg-indigo-600 text-white text-sm rounded-r-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Search
              </button>
            </div>
            
            {noDataAlert && (
              <div className="mt-2 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-2 text-sm" role="alert">
                <div className="flex">
                  <ExclamationCircleIcon className="h-5 w-5 text-yellow-400 mr-2" aria-hidden="true" />
                  <p>{noDataAlert}</p>
                </div>
              </div>
            )}
            
            {specificAnalytics && (
              <div className="mt-4 grid grid-cols-2 gap-4">
                {!isUserSearch && (
                  <AnalyticCard icon={<UserIcon className="h-5 w-5" />} title="Total Users" value={specificAnalytics.total_users} />
                )}
                <AnalyticCard icon={<MagnifyingGlassIcon className="h-5 w-5" />} title="Emails Processed" value={specificAnalytics.emails_processed} />
                <AnalyticCard icon={<PencilIcon className="h-5 w-5" />} title="Drafts Written" value={specificAnalytics.drafts_written} />
                <AnalyticCard icon={<CurrencyDollarIcon className="h-5 w-5" />} title="Total Cost" value={`$${specificAnalytics.total_cost.toFixed(2)}`} />
                <AnalyticCard icon={<ClockIcon className="h-5 w-5" />} title="Time Saved" value={`${specificAnalytics.time_saved} mins`} />
                <AnalyticCard icon={<ChartBarIcon className="h-5 w-5" />} title="Efficiency Gain" value={`${((specificAnalytics.time_saved / 60) / 8).toFixed(2)} days`} />
              </div>
            )}
          </div>
        </div>

        {/* Right column */}
        <div>
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Analytics Over Time {selectedOption ? `(${isUserSearch ? 'User' : 'Organization'})` : '(All Users)'}</h3>
            <div className="flex items-center mb-2">
              <label htmlFor="timeSeriesInterval" className="block text-sm font-medium text-gray-700 mr-2">Select Interval</label>
              <select
                id="timeSeriesInterval"
                value={timeSeriesInterval}
                onChange={(e) => {
                  setTimeSeriesInterval(e.target.value as 'day' | 'week' | 'month');
                  fetchChartData();
                }}
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="day">Daily</option>
                <option value="week">Weekly</option>
                <option value="month">Monthly</option>
              </select>
              <button
                onClick={fetchChartData}
                className="ml-2 px-3 py-2 bg-indigo-600 text-white text-sm rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Refresh
              </button>
            </div>
            <div className="h-80">
              <TimeSeriesChart data={chartData} interval={timeSeriesInterval} />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <Analytics isAdmin={true} />
      </div>
    </div>
  );
};

const AnalyticCard: React.FC<{ icon: React.ReactNode, title: string, value: string | number }> = ({ icon, title, value }) => (
  <div className="bg-white rounded-lg shadow p-3 flex items-center">
    <div className="bg-indigo-100 rounded-full p-2 mr-3">
      {React.cloneElement(icon as React.ReactElement, { className: "text-indigo-600 text-sm" })}
    </div>
    <div>
      <h4 className="text-xs font-semibold text-gray-600">{title}</h4>
      <p className="text-sm font-bold text-indigo-600">{value}</p>
    </div>
  </div>
);

export default AdminDashboard;