import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import PageLayout from '../components/layout/PageLayout';
import { ScaleIcon, UserGroupIcon, ClipboardDocumentCheckIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import EnvelopeMailBgImage from "../images/background/envelope-mail.png";

const TermsAndConditionsPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Terms and Conditions | Inktuitive Email';
  }, []);

  useEffect(() => {
    document.body.classList.add('scrollable-page');
    return () => {
      document.body.classList.remove('scrollable-page');
    };
  }, []);

  const keyPoints = [
    {
      title: 'Use of Service',
      content: 'By using Inktuitive Email, you agree to comply with these terms and all applicable laws and regulations.',
      icon: <UserGroupIcon className="h-8 w-8" />
    },
    {
      title: 'User Responsibilities',
      content: 'You are responsible for maintaining the confidentiality of your account and for all activities that occur under your account.',
      icon: <ClipboardDocumentCheckIcon className="h-8 w-8" />
    },
    {
      title: 'Service Modifications',
      content: 'We reserve the right to modify or discontinue, temporarily or permanently, the service with or without notice.',
      icon: <ScaleIcon className="h-8 w-8" />
    },
  ];

  return (
    <PageLayout>
      {/* Hero Banner */}
      <motion.div
        className="relative bg-gradient-to-br from-indigo-600 to-purple-600 text-white py-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div 
          className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat opacity-10"
          style={{
            backgroundImage: `url(${EnvelopeMailBgImage})`,
          }}
        ></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl mb-4">
            Terms and Conditions
          </h1>
          <p className="text-xl max-w-3xl">
            Please read these terms carefully before using Inktuitive Email's services.
          </p>
        </div>
      </motion.div>

      {/* Main Content */}
      <motion.div
        className="flex-grow bg-gradient-to-br from-indigo-50 to-purple-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="py-12 sm:py-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto w-full">
            {/* Introduction */}
            <motion.div
              className="mb-16"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <h2 className="text-3xl font-semibold mb-6 text-gray-800">Welcome to Inktuitive Email</h2>
              <p className="text-gray-600 mb-4">
                These Terms and Conditions govern your use of Inktuitive Email's services. By accessing or using our services, you agree to be bound by these terms. If you disagree with any part of the terms, you may not use our services.
              </p>
            </motion.div>
            
            {/* Key Points */}
            <motion.div
              className="mb-16"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <h2 className="text-3xl font-semibold mb-6 text-gray-800">Key Points</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {keyPoints.map((point, index) => (
                  <motion.div
                    key={index}
                    className="bg-white p-6 rounded-lg shadow-lg"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.5 + index * 0.1 }}
                  >
                    <div className="text-indigo-500 mb-4">{point.icon}</div>
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">{point.title}</h3>
                    <p className="text-gray-600">{point.content}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>
            
            {/* Full Terms and Conditions */}
            <motion.div
              className="mb-16"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <h2 className="text-3xl font-semibold mb-6 text-gray-800">Terms and Conditions</h2>
                <div className="bg-white p-8 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold mb-4 text-gray-800">1. Introduction</h3>
                <p className="text-gray-600 mb-4">
                    Welcome to Inktuitive Email ("the Service"), provided by Inktuitive ("we," "us," or "our"). By accessing or using our web application, you agree to be bound by these Terms and Conditions ("Terms"). If you disagree with any part of these Terms, you may not use our Service.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">2. Description of Service</h3>
                <p className="text-gray-600 mb-4">
                    Inktuitive Email is a web application that utilizes artificial intelligence (AI) to sort your emails into user-defined categories and draft email responses. The Service currently supports Gmail accounts, with Microsoft Outlook support planned for the future.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">3. User Accounts</h3>
                <p className="text-gray-600 mb-4">
                    To use our Service, you must create an account and connect it to your email provider using OAuth 2.0. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">4. User Responsibilities</h3>
                <p className="text-gray-600 mb-4">
                    You agree to use the Service only for lawful purposes and in accordance with these Terms. You are responsible for all content you provide to the Service, including email content, categories, and settings. You agree not to use the Service to:
                </p>
                <ul className="list-disc pl-5 mb-4 text-gray-600">
                    <li>Violate any applicable laws or regulations</li>
                    <li>Infringe on the rights of others</li>
                    <li>Transmit any material that is unlawful, offensive, or harmful</li>
                    <li>Impersonate any person or entity</li>
                    <li>Interfere with or disrupt the Service or servers connected to the Service</li>
                </ul>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">5. Intellectual Property</h3>
                <p className="text-gray-600 mb-4">
                    The Service and its original content, features, and functionality are owned by Inktuitive and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Service, except as incidental to normal web browsing or as expressly permitted by us in writing.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">6. Third-Party Services</h3>
                <p className="text-gray-600 mb-4">
                    Our Service utilizes third-party services, including but not limited to OpenAI and Anthropic for AI processing, and Postmark for email-related functions. Your use of the Service is also subject to any applicable terms of service or privacy policies of these third-party providers.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">7. Privacy and Data Protection</h3>
                <p className="text-gray-600 mb-4">
                    Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms by reference. By using the Service, you consent to the collection, use, and sharing of your information as described in our Privacy Policy.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">8. Disclaimer of Warranties</h3>
                <p className="text-gray-600 mb-4">
                    The Service is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. We do not warrant that the Service will be uninterrupted, timely, secure, or error-free. We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">9. Limitation of Liability</h3>
                <p className="text-gray-600 mb-4">
                    In no event shall Inktuitive, its affiliates, or their respective directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">10. Indemnification</h3>
                <p className="text-gray-600 mb-4">
                    You agree to indemnify and hold harmless Inktuitive and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to or use of the Service or your violation of these Terms.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">11. Modifications to the Service</h3>
                <p className="text-gray-600 mb-4">
                    We reserve the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. We shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">12. Termination</h3>
                <p className="text-gray-600 mb-4">
                    We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever, including without limitation if you breach these Terms. Upon termination, your right to use the Service will immediately cease.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">13. Governing Law</h3>
                <p className="text-gray-600 mb-4">
                    These Terms shall be governed and construed in accordance with the laws of New Zealand, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">14. Changes to These Terms</h3>
                <p className="text-gray-600 mb-4">
                    We reserve the right to update or change these Terms at any time and you should check these Terms periodically. Your continued use of the Service after we post any modifications to the Terms will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Terms.
                </p>

                <h3 className="text-2xl font-semibold mb-4 text-gray-800">15. Contact Us</h3>
                <p className="text-gray-600 mb-4">
                    If you have any questions about these Terms, please contact us through the support dashboard in the App.
                </p>

                <p className="text-gray-600 mt-8">
                    By using Inktuitive Email, you acknowledge that you have read and understood these Terms and Conditions and agree to be bound by them.
                </p>
                </div>
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* CTA Section */}
      <motion.section
        className="py-20 bg-gradient-to-r from-indigo-600 to-purple-600 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-extrabold mb-6">Questions About Our Terms?</h2>
          <p className="text-xl mb-8">
            If you have any questions about our Terms and Conditions, please don't hesitate to reach out to us.
          </p>
          <div className="flex justify-center space-x-4">
            <a
              href="/contact"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-50 transition-colors duration-300"
            >
              Contact Us
              <ArrowRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </a>
          </div>
        </div>
      </motion.section>
    </PageLayout>
  );
};

export default TermsAndConditionsPage;