import React, { useState, useEffect } from 'react';
import { getUserAnalytics, getTotalAnalytics } from '../services/api';
import { Line } from 'react-chartjs-2';

const Analytics: React.FC<{ userId?: number; isAdmin: boolean }> = ({ userId, isAdmin }) => {
  const [userAnalytics, setUserAnalytics] = useState<any[]>([]);
  const [totalAnalytics, setTotalAnalytics] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (userId) {
        const userData = await getUserAnalytics(userId, 'last_30_days'); // or use a prop for timeframe
        setUserAnalytics(userData);
      }
      if (isAdmin) {
        const totalData = await getTotalAnalytics('last_30_days'); // or use a prop for timeframe
        setTotalAnalytics(totalData);
      }
    };
    fetchData();
  }, [userId, isAdmin]);

  const renderChart = (data: any[], label: string) => {
    const chartData = {
      labels: data.map(item => item.date),
      datasets: [
        {
          label: 'Emails Processed',
          data: data.map(item => item.emails_processed),
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        },
        {
          label: 'Drafts Written',
          data: data.map(item => item.drafts_written),
          borderColor: 'rgb(255, 99, 132)',
          tension: 0.1
        },
        {
          label: 'Total Cost ($)',
          data: data.map(item => item.total_cost),
          borderColor: 'rgb(54, 162, 235)',
          tension: 0.1
        }
      ]
    };

    return (
      <div>
        <h2>{label}</h2>
        <Line data={chartData} />
      </div>
    );
  };

  return (
    <div>
      {userId && userAnalytics.length > 0 && renderChart(userAnalytics, 'User Analytics')}
      {isAdmin && totalAnalytics.length > 0 && renderChart(totalAnalytics, 'Total Analytics')}
    </div>
  );
};

export default Analytics;