// File: frontend/src/components/settings/UserContextSettings.tsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  userContextApi,
  UserContextSettings as UserContextSettingsType,
} from '../../services/userContextApi';
import { useToast } from '../../context/ToastContext';

interface UserContextSettingsProps {
  userId: number;
}

const UserContextSettings: React.FC<UserContextSettingsProps> = ({ userId }) => {
  const [settings, setSettings] = useState<UserContextSettingsType>({
    id: 0,
    user_id: userId,
    name: '',
    role: '',
    personal_context: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const currentUser = useSelector((state: RootState) => state.auth.user);

  const didFetchSettings = useRef(false);

  const { showToast } = useToast();

  const fetchSettings = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await userContextApi.getUserContextSettings(userId);
      setSettings(data);
      setError(null);
      showToast('User context settings loaded successfully', 'success');
    } catch (err) {
      console.error('Error fetching user context settings:', err);
      setError('Failed to fetch user context settings. Please try again later.');
      showToast('Failed to load user context settings', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [showToast, userId]);

  useEffect(() => {
    if (!didFetchSettings.current) {
      fetchSettings();
      didFetchSettings.current = true;
    }
  }, [userId, fetchSettings]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSettings((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await userContextApi.updateUserContextSettings(userId, settings);
      setError(null);
      showToast('User context settings updated successfully', 'success');
    } catch (err) {
      console.error('Error updating user context settings:', err);
      setError('Failed to update user context settings. Please try again.');
      showToast('Failed to update user context settings', 'error');
    }
  };

  if (isLoading) return <div className="text-center py-8">Loading...</div>;

  const isAdmin = currentUser?.role === 'admin';
  const isOwnSettings = currentUser?.id === userId;

  return (
    <div className="space-y-6">
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={settings.name}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              readOnly={!isAdmin && !isOwnSettings}
              placeholder="Enter full name"
            />
          </div>

          <div>
            <label
              htmlFor="role"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Role
            </label>
            <input
              type="text"
              id="role"
              name="role"
              value={settings.role}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              readOnly={!isAdmin && !isOwnSettings}
              placeholder="Enter job title"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="personal_context"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Personal Context
          </label>
          <textarea
            id="personal_context"
            name="personal_context"
            value={settings.personal_context}
            onChange={handleInputChange}
            rows={10}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            readOnly={!isAdmin && !isOwnSettings}
            placeholder="Enter personal context information. Example:
          Personal Context for Joe Blogs:
          - Senior Marketing Manager at GlobalTech Solutions
          - Manages 10-person team, reports to CMO
          - Approves marketing budgets up to $100k
          - Works closely with Sales and Product teams
          - Direct communication style, prefers morning meetings
          - 5 years at company, focuses on data-driven strategies"
          />
        </div>

        {(isAdmin || isOwnSettings) && (
          <div className="flex justify-end pt-4 border-t border-gray-200">
            <button
              type="submit"
              className="px-4 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-md hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
            >
              Save Changes
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default UserContextSettings;
