import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import Toast from "../components/layout/Toast";

interface ToastContextType {
  showToast: (
    message: string,
    type: "info" | "success" | "warning" | "error",
  ) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [toasts, setToasts] = useState<
    Array<{
      id: number;
      message: string;
      type: "info" | "success" | "warning" | "error";
    }>
  >([]);

  const showToast = useCallback(
    (message: string, type: "info" | "success" | "warning" | "error") => {
      const id = Date.now();
      setToasts((prevToasts) => [{ id, message, type }, ...prevToasts]);
    },
    [],
  );

  const closeToast = useCallback((id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <div className="fixed bottom-4 right-4 z-50 flex flex-col items-end space-y-2">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            message={toast.message}
            type={toast.type}
            onClose={() => closeToast(toast.id)}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
