import React, { useEffect, useRef, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface ToastProps {
  message: string;
  type: "info" | "success" | "warning" | "error";
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, type, onClose }) => {
  // times are defined in milliseconds;
  const displayTime = 5000;
  const fadeDuration = 500;

  const isMounted = useRef(false);

  const [show, setShow] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    let fade: NodeJS.Timeout | undefined;
    if (isMounted.current && show) {
      fade = setTimeout(() => {
        setShow(false);
      }, displayTime - fadeDuration);
      timer = setTimeout(() => {
        onClose();
      }, displayTime);
    }
    return () => {
      if (isMounted.current === false) {
        clearTimeout(timer);
        clearTimeout(fade);
      }
    }
  }, [onClose, show]);

  const getIcon = () => {
    switch (type) {
      case "info":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-current flex-shrink-0 w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        );
      case "success":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current flex-shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
      case "warning":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current flex-shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        );
      case "error":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current flex-shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
    }
  };

  const getBackgroundColor = () => {
    switch (type) {
      case "info":
        return "bg-blue-100 border-blue-500";
      case "success":
        return "bg-green-100 border-green-500";
      case "warning":
        return "bg-yellow-100 border-yellow-500";
      case "error":
        return "bg-red-100 border-red-500";
    }
  };

  return (
    <div
      className={`relative rounded-lg shadow-lg p-4 pr-10 max-w-sm w-full border-l-4 ${getBackgroundColor()} animate-fade-in opacity-0 transition-opacity ease-linear duration-500 ${show && "opacity-100"}`}
    >
      <button
        onClick={onClose}
        className="absolute top-1 right-1 text-gray-500 hover:text-gray-700 transition-colors duration-200"
        aria-label="Close notification"
      >
        <XMarkIcon className="h-5 w-5" />
      </button>
      <div className="flex items-center space-x-3">
        {getIcon()}
        <span className="text-sm font-medium text-gray-800">{message}</span>
      </div>
    </div>
  );
};

export default Toast;
