// File: frontend/src/components/admin/UserActions.tsx

import { useState } from 'react';
import { User } from '../../types/user';
import { createUser, updateUser, deleteUser } from '../../services/api';
import { useToast } from '../../context/ToastContext'; // Import the useToast hook

export const useUserActions = (
  setUsers: React.Dispatch<React.SetStateAction<User[]>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>
) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast(); // Use the useToast hook

  const handleCreateUser = async (userData: Partial<User>) => {
    setIsLoading(true);
    setError(null);
    try {
      const newUser = await createUser(userData);
      setUsers(prevUsers => [...prevUsers, newUser]);
      showToast('User created successfully', 'success');
    } catch (error) {
      console.error('Error creating user:', error);
      setError('Failed to create user. Please try again.');
      showToast('Failed to create user', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateUser = async (userId: number, userData: Partial<User>) => {
    setIsLoading(true);
    setError(null);
    try {
      const updatedUser = await updateUser(userId, userData);
      console.log(updatedUser);
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === userId ? { ...user, ...updatedUser } : user
        )
      );
      showToast('User updated successfully', 'success');
    } catch (error) {
      console.error('Error updating user:', error);
      setError('Failed to update user. Please try again.');
      showToast('Failed to update user', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async (userId: number) => {
    setIsLoading(true);
    setError(null);
    try {
      await deleteUser(userId);
      setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
      showToast('User deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting user:', error);
      setError('Failed to delete user. Please try again.');
      showToast('Failed to delete user', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleCreateUser,
    handleUpdateUser,
    handleDeleteUser,
    isLoading
  };
};