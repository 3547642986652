// file: frontend/src/services/agentApi.ts

import api from './api';

export const getAgents = async () => {
  const response = await api.get('/admin/agents'); // Use pre-configured `api`
  return response.data;
};

export const getAgent = async (agentName: string) => {
  const response = await api.get(`/admin/agents/${agentName}`);
  return response.data;
};

export const createAgent = async (agentData: any) => {
  const response = await api.post('/admin/agents', agentData);
  return response.data;
};

export const updateAgent = async (agentName: string, agentData: any) => {
  const response = await api.put(`/admin/agents/${agentName}`, agentData);
  return response.data;
};

export const deleteAgent = async (agentName: string) => {
  const response = await api.delete(`/admin/agents/${agentName}`);
  return response.data;
};


/**
 * Runs the Determine Writing Style agent.
 * This function calls the backend API to trigger the agent.
 */
/**
 * Enqueue the Determine Writing Style task via Celery.
 * @param userId - The ID of the user.
 * @returns A promise that resolves to the task ID and status.
 */
export const runDetermineWritingStyle = async (userId: number): Promise<{ task_id: string; status: string }> => {
  const url = `/admin/agents/run_determine_writing_style/${userId}`;
  console.log('Calling API:', url, 'Method: POST');
  try {
    const response = await api.post(url);
    console.log('API response:', response);
    return response.data;
  } catch (error: any) {
    console.error('API error:', error);
    console.error('Error response:', error.response);
    throw error.response?.data || { message: 'An error occurred while running the agent.' };
  }
};

/**
 * Fetch the status of a Celery task.
 * @param taskId - The ID of the task.
 * @returns A promise that resolves to the task status and data.
 */
export const getTaskStatus = async (taskId: string): Promise<{ status: string; data?: any; detail?: string }> => {
  const url = `/admin/agents/task_status/${taskId}`;
  console.log('Calling API:', url, 'Method: GET');
  try {
    const response = await api.get(url);
    console.log('API response:', response);
    
    // Directly return the response data without modifying the status
    return {
      status: response.data.status,
      data: response.data.data,
      detail: response.data.detail
    };
  } catch (error: any) {
    console.error('API error:', error);
    console.error('Error response:', error.response);
    throw error.response?.data || { message: 'An error occurred while fetching task status.' };
  }
};

export const generateRulesForCategory = async (categoryName: string, userId: number) => {
  try {
    const response = await api.post(`/admin/agents/generate-rules/${userId}`, null, {
      params: { category_name: categoryName }
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      throw error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from server');
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error('Error setting up the request');
    }
  }
};
