import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { XMarkIcon, TrashIcon } from '@heroicons/react/24/outline';
import { User } from '../../types/user';
import { Organization, BusinessContextSettings as BusinessContextSettingsType } from '../../types/settings';
import businessSettingsApi from '../../services/businessSettingsApi';
import BusinessContextSettings from '../settings/BusinessContextSettings';

interface OrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization | null;
  refreshOrganizations: () => Promise<void>;
  isCreating: boolean;
}

const OrganizationModal: React.FC<OrganizationModalProps> = ({
  isOpen,
  onClose,
  organization,
  refreshOrganizations,
  isCreating,
}) => {
  const [, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [settings, setSettings] = useState<BusinessContextSettingsType | null>(null);
  const [orgName, setOrgName] = useState('');
  const [orgDomain, setOrgDomain] = useState('');
  const [createdOrganization, setCreatedOrganization] = useState<Organization | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const resetForm = () => {
    setIsLoading(false);
    setError(null);
    setOrgName('');
    setOrgDomain('');
    setCreatedOrganization(null);
    setSettings(null);
  };

  const fetchOrganizationData = useCallback(async (org: Organization) => {
    try {
      setIsLoading(true);
      setError(null);
      await Promise.all([
        fetchOrganizationUsers(org.id),
        fetchOrganizationBusinessContext(org.id),
      ]);
      setOrgName(org.name);
      setOrgDomain(org.domain);
      setCreatedOrganization(org);
    } catch (err) {
      setError('Failed to fetch organization data');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchOrganizationUsers = async (orgId: number) => {
    try {
      const fetchedUsers = await businessSettingsApi.getUsersForOrganization(orgId);
      setUsers(fetchedUsers);
    } catch (err) {
      console.error('Failed to fetch users for organization:', err);
    }
  };

  const fetchOrganizationBusinessContext = async (orgId: number) => {
    try {
      const fetchedSettings = await businessSettingsApi.getOrganizationBusinessContext(orgId);
      setSettings(fetchedSettings || {
        organization_id: orgId,
        industry: '',
        key_offerings: [],
        associated_businesses: [],
        additional_context: '',
      });
    } catch (err) {
      console.error('Failed to fetch business context settings:', err);
    }
  };

  const handleSaveBusinessContext = async (updatedSettings: BusinessContextSettingsType) => {
    try {
      setIsLoading(true);
      setError(null);
      if (createdOrganization) {
        await businessSettingsApi.updateOrganizationBusinessContext(createdOrganization.id, updatedSettings);
        setSettings(updatedSettings);
        await refreshOrganizations();
        onClose();
      }
    } catch (err) {
      setError('Failed to save business context settings');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateOrganization = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!orgName.trim() || !orgDomain.trim()) {
      setError('Organization name and domain are required');
      return;
    }
    try {
      setIsLoading(true);
      setError(null);
      const newOrg = await businessSettingsApi.createOrganization({
        name: orgName,
        domain: orgDomain,
      });
      setCreatedOrganization(newOrg);
      await fetchOrganizationData(newOrg);
      await refreshOrganizations();
      onClose();
    } catch (err) {
      setError('Failed to create organization');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteOrganization = async () => {
    if (organization) {
      try {
        setIsLoading(true);
        setError(null);
        await businessSettingsApi.deleteOrganization(organization.id);
        await refreshOrganizations();
        onClose();
      } catch (err) {
        setError('Failed to delete organization');
      } finally {
        setIsLoading(false);
        setIsDeleteModalOpen(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (organization && !isCreating) {
        fetchOrganizationData(organization);
      } else {
        resetForm();
      }
    }
  }, [isOpen, organization, isCreating, fetchOrganizationData]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="modal-container mt-16" // Use modal-container for background overlay
        >
          <div className="modal-content"> {/* Use modal-content for the modal box */}
            <div className="modal-header"> {/* Use modal-header for the header */}
              <h2 className="text-2xl font-bold text-gray-800">
                {isCreating ? 'Create Organization' : `Organization: ${orgName}`}
              </h2>
              <div className="flex items-center">
                {!isCreating && (
                  <button
                    className="text-red-500 hover:text-red-700 mr-4"
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    <TrashIcon className="h-6 w-6" />
                  </button>
                )}
                <button
                  onClick={onClose}
                  className="text-gray-500 hover:text-gray-700 transition duration-150"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
  
            <div className="p-6 space-y-6">
              {isLoading ? (
                <div className="text-center py-8">Loading...</div>
              ) : (
                <>
                  {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                      <span className="block sm:inline">{error}</span>
                    </div>
                  )}
  
                  {isCreating ? (
                    <form onSubmit={handleCreateOrganization} className="space-y-4">
                      <div>
                        <label htmlFor="orgName" className="block text-sm font-medium text-gray-700 mb-1">
                          Organisation Name
                        </label>
                        <input
                          type="text"
                          id="orgName"
                          value={orgName}
                          onChange={(e) => setOrgName(e.target.value)}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          required
                        />
                      </div>
  
                      <div>
                        <label htmlFor="orgDomain" className="block text-sm font-medium text-gray-700 mb-1">
                          Organisation Domain
                        </label>
                        <input
                          type="text"
                          id="orgDomain"
                          value={orgDomain}
                          onChange={(e) => setOrgDomain(e.target.value)}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          required
                        />
                      </div>
  
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Create Organisation
                      </button>
                    </form>
                  ) : (
                    <BusinessContextSettings
                      settings={settings || {
                        organization_id: createdOrganization?.id || organization?.id || 0,
                        industry: '',
                        key_offerings: [],
                        associated_businesses: [],
                        additional_context: '',
                      }}
                      organizationId={createdOrganization?.id || organization?.id || 0}
                      organizationName={createdOrganization?.name || organization?.name || ''}
                      organizationDomain={createdOrganization?.domain || organization?.domain || ''}
                      onSave={handleSaveBusinessContext}
                      onDelete={handleDeleteOrganization}
                      showUserManagement={true}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </motion.div>
      )}
  
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="text-xl font-bold mb-4">Delete Organisation</h3>
            <p className="mb-6">Are you sure you want to delete this organisation?</p>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-300 rounded-md mr-2 hover:bg-gray-400 transition duration-300"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300"
                onClick={handleDeleteOrganization}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
  

};

export default OrganizationModal;
