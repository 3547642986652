import React, { useState, useEffect, useRef } from 'react';
import { updateUserSignature, getUserSignature } from '../../services/api';
import { useToast } from '../../context/ToastContext';

interface SignatureSettingsProps {
  userId: number;
}

const SignatureSettings: React.FC<SignatureSettingsProps> = ({ userId }) => {
  const [signature, setSignature] = useState('');
  const [previewSignature, setPreviewSignature] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const didFetchSignature = useRef(false);
  const { showToast } = useToast();

  useEffect(() => {
    const fetchSignature = async () => {
      setIsLoading(true);
      try {
        const fetchedSignature = await getUserSignature(userId);
        setSignature(fetchedSignature);
        setPreviewSignature(fetchedSignature);
        setError(null);
        showToast('Signature loaded successfully', 'success');
      } catch (err) {
        setError('Failed to fetch signature');
        console.error('Error fetching signature:', err);
        showToast('Failed to load signature', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    if (!didFetchSignature.current) {
      fetchSignature();
      didFetchSignature.current = true;
    }
  }, [userId, showToast]);

  const handleSignatureChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSignature(e.target.value);
    setPreviewSignature(e.target.value);
  };

  const handleSaveSignature = async () => {
    setIsLoading(true);
    try {
      await updateUserSignature(userId, { signature });
      setError(null);
      showToast('Signature saved successfully', 'success');
    } catch (err) {
      setError('Failed to save signature');
      console.error('Error saving signature:', err);
      showToast('Failed to save signature', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div className="text-center py-4 sm:py-8">Loading...</div>;

  return (
    <div className="space-y-4 sm:space-y-6">
      <h2 className="text-xl sm:text-2xl font-bold text-gray-800">Email Signature</h2>

      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-3 py-2 sm:px-4 sm:py-3 rounded relative text-sm sm:text-base"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <div>
        <label htmlFor="signature" className="block text-sm font-medium text-gray-700 mb-1">
          Your Signature
        </label>
        <textarea
          id="signature"
          rows={6}
          value={signature}
          onChange={handleSignatureChange}
          className="w-full px-3 py-2 text-sm sm:text-base border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="Enter your email signature here"
        />
      </div>

      <div>
        <h3 className="text-base sm:text-lg font-medium text-gray-800 mb-2">Preview</h3>
        <div className="border border-gray-300 rounded-md bg-white overflow-hidden">
          <div className="p-3 sm:p-4 bg-gray-50 border-b border-gray-300">
            <div className="text-sm text-gray-600">From: Your Name &lt;your.email@example.com&gt;</div>
            <div className="text-sm text-gray-600">To: Recipient &lt;recipient@example.com&gt;</div>
          </div>
          <div className="p-3 sm:p-4">
            <div className="text-sm sm:text-base mb-4">
              Email body content...
            </div>
            <div
              className="text-sm sm:text-base signature-preview"
              dangerouslySetInnerHTML={{ __html: previewSignature }}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end pt-4 border-t border-gray-200">
        <button
          onClick={handleSaveSignature}
          className="w-full sm:w-auto px-4 py-2 text-sm sm:text-base bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-md hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
          disabled={isLoading}
        >
          Save Signature
        </button>
      </div>
    </div>
  );
};

export default SignatureSettings;
