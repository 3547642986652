// File: frontend/src/components/layout/Toggle.tsx

import React, { ChangeEvent } from 'react';

interface ToggleProps {
  checked: boolean;
  onChange: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void; // Update to ChangeEvent
  id: string;
  label: string;
}

const Toggle: React.FC<ToggleProps> = ({ checked, onChange, id, label }) => {
  return (
    <div className="flex items-center">
      <label htmlFor={id} className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            id={id}
            className="sr-only"
            checked={checked}
            onChange={(e) => onChange(e.target.checked, e)}  // Pass ChangeEvent
          />
          <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner transition-colors duration-300 ease-in-out"></div>
          <div className={`absolute left-0 top-[-3px] w-6 h-6 bg-white rounded-full shadow transition-transform duration-300 ease-in-out transform ${checked ? 'translate-x-full bg-gradient-to-r from-indigo-600 to-purple-600' : 'translate-x-0'}`}></div>
        </div>
        <div className="ml-3 text-gray-700 font-medium">
          {label}
        </div>
      </label>
    </div>
  );
};

export default Toggle;
