import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { User } from '../../types/user';
import { Organization } from '../../types/settings';
import { getUsers } from '../../services/api';
import businessSettingsApi from '../../services/businessSettingsApi';
import UserList from './UserList';
import UserModal from './UserModal';
import { useUserActions } from './UserActions';
import { debounce } from 'lodash';

const UsersManagement: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [, setOrganizations] = useState<Organization[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [organizationFilter, setOrganizationFilter] = useState<string>('');
    const [domainFilter, setDomainFilter] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10;
    
    const [sortField, setSortField] = useState<string>('email');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

    const { handleCreateUser, handleUpdateUser, } = useUserActions(setUsers, setError);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const [fetchedUsers, fetchedOrganizations] = await Promise.all([
                getUsers(),
                businessSettingsApi.getOrganizations(),
            ]);
            
            const usersWithOrg = fetchedUsers.map(user => ({
                ...user,
                organizationName: user.organization?.name || 'N/A',
            }));
            
            setUsers(usersWithOrg);
            setOrganizations(fetchedOrganizations);
            setError(null);
        } catch (error) {
            console.error('Error fetching data:', error);
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 403) {
                    setError('You do not have permission to view the user list. Please contact an administrator.');
                } else {
                    setError('An error occurred while fetching users. Please try again later.');
                }
            } else {
                setError('An unexpected error occurred. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = useMemo(() => debounce((value: string) => {
        setSearchTerm(value);
        setCurrentPage(1);
    }, 300), []);

    useEffect(() => {
        return () => {
            handleSearch.cancel();
        };
    }, [handleSearch]);

    const organizationOptions = useMemo(() => {
        return Array.from(new Set(users.map(user => user.organizationName))).filter(org => org !== 'N/A');
    }, [users]);

    const domainOptions = useMemo(() => {
        return Array.from(new Set(users.map(user => {
            const parts = user.email.split('@');
            return parts.length > 1 ? parts[1].toLowerCase() : '';
        }))).filter(Boolean);
    }, [users]);

    const filteredUsers = useMemo(() => {
        return users.filter(user => {
            const matchesSearchTerm = user.email.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesOrganization = organizationFilter ? user.organizationName === organizationFilter : true;
            const matchesDomain = domainFilter ? user.email.toLowerCase().endsWith(`@${domainFilter.toLowerCase()}`) : true;
            return matchesSearchTerm && matchesOrganization && matchesDomain;
        });
    }, [users, searchTerm, organizationFilter, domainFilter]);

    const sortedUsers = useMemo(() => {
        return [...filteredUsers].sort((a, b) => {
            const fieldA = (a as any)[sortField];
            const fieldB = (b as any)[sortField];
            if (fieldA < fieldB) return sortOrder === 'asc' ? -1 : 1;
            if (fieldA > fieldB) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    }, [filteredUsers, sortField, sortOrder]);

    const paginatedUsers = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return sortedUsers.slice(startIndex, startIndex + itemsPerPage);
    }, [sortedUsers, currentPage]);

    const openModal = (user: User | null = null) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedUser(null);
        setIsModalOpen(false);
        fetchData();
    };

    const handleFormSubmit = (userData: Partial<User>) => {
        if (selectedUser) {
            handleUpdateUser(selectedUser.id, userData);
        } else {
            handleCreateUser(userData);
        }
        closeModal();
    };

    const handleToggleChange = (userId: number, isActive: boolean) => {
        handleUpdateUser(userId, { listener_active: isActive });
    };

    const handleSort = (field: string) => {
        const newSortOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newSortOrder);
    };

    return (
        <div className="w-full px-4 sm:px-6 lg:px-8 py-4 sm:py-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4 sm:mb-6">
                <input
                    type="text"
                    placeholder="Search users..."
                    onChange={(e) => handleSearch(e.target.value)}
                    className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <select
                    value={organizationFilter}
                    onChange={(e) => { setOrganizationFilter(e.target.value); setCurrentPage(1); }}
                    className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                    <option value="">All Organisations</option>
                    {organizationOptions.map(org => (
                        <option key={org} value={org}>{org}</option>
                    ))}
                </select>
                <select
                    value={domainFilter}
                    onChange={(e) => { setDomainFilter(e.target.value); setCurrentPage(1); }}
                    className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                    <option value="">All Domains</option>
                    {domainOptions.map(domain => (
                        <option key={domain} value={domain}>{domain}</option>
                    ))}
                </select>
                <button
                    onClick={() => openModal()}
                    className="w-full sm:w-auto px-4 py-2 bg-indigo-600 text-white text-sm rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Create User
                </button>
            </div>

            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <span className="block sm:inline">{error}</span>
                    <button
                        onClick={fetchData}
                        className="absolute top-0 bottom-0 right-0 px-4 py-3"
                    >
                        <span className="text-red-500 hover:text-red-600">Retry</span>
                    </button>
                </div>
            )}

            {loading ? (
                <div className="flex justify-center items-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
                </div>
            ) : (
                <>
                    <UserList
                        users={paginatedUsers}
                        onEditUser={openModal}
                        onToggleChange={handleToggleChange}
                        onSort={handleSort}
                        sortField={sortField}
                        sortOrder={sortOrder}
                    />
                    
                    <div className="flex justify-between items-center mt-4">
                        <button
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className="px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
                        >
                            Previous
                        </button>
                        <span className="text-sm text-gray-500">
                            Page {currentPage} of {Math.ceil(sortedUsers.length / itemsPerPage)}
                        </span>
                        <button
                            onClick={() => setCurrentPage(prev => prev + 1)}
                            disabled={currentPage * itemsPerPage >= sortedUsers.length}
                            className="px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
                        >
                            Next
                        </button>
                    </div>
                </>
            )}

            <UserModal
                isOpen={isModalOpen}
                onClose={closeModal}
                user={selectedUser}
                onFormSubmit={handleFormSubmit}
                onUpdateUser={handleUpdateUser}
            />
        </div>
    );
};

export default UsersManagement;